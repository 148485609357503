// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list {
  --padding: 0;
}

.grid-search-background {
  color: white;
  background-color: #1b171b;
}

ion-item {
  --background: #1b171b;
  --border-color: var(--ion-color-light);
  --padding-start: 0;
  --inner-padding-end: 10px;
  --highlight-color-valid: var(--ion-color-light);
}

ion-select::part(text) {
  color: var(--ion-color-light);
}

.button-row-padding {
  padding-right: 10px;
  padding-bottom: 8px;
}

.spinner-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  color: white;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-color {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/search/floorplan-searchpopover/floorplan-searchpopover.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AACA;EACI,YAAA;EACA,yBAAA;AAEJ;;AAAA;EACI,qBAAA;EACA,sCAAA;EACA,kBAAA;EACA,yBAAA;EACA,+CAAA;AAGJ;;AAAA;EACI,6BAAA;AAGJ;;AAAA;EACI,mBAAA;EACA,mBAAA;AAGJ;;AADA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,YAAA;EACA,QAAA;EACA,+BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAIJ;;AAFA;EACI,YAAA;AAKJ","sourcesContent":["ion-list {\n    --padding: 0;\n}\n.grid-search-background {\n    color: white;\n    background-color: #1b171b;\n}\nion-item {\n    --background: #1b171b;\n    --border-color: var(--ion-color-light);\n    --padding-start: 0;\n    --inner-padding-end: 10px;\n    --highlight-color-valid: var(--ion-color-light);\n}\n\nion-select::part(text) {\n    color: var(--ion-color-light);\n  }\n\n.button-row-padding {\n    padding-right: 10px;\n    padding-bottom: 8px;\n}\n.spinner-background {\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    color: white;\n    right: 0;\n    background: rgba(0, 0, 0, 0.15);\n    z-index: 999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.label-color{\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
