import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatetimeFormatService {
  public selectedDateTimeFormat$: BehaviorSubject<string> = new BehaviorSubject(
    'en-GB'
  );
  public isDefault = true;

  allowedLocales = [
    'en-US',
    'en-GB',
    'de-DE',
    'fr-FR',
    'es-ES',
    'zh-CN',
    'ja-JP',
    'ko-KR',
    'ru-RU',
    'pt-BR',
  ];

  STORAGEKEY: string = 'selectedDateTimeFormat';

  constructor(private translationService: TranslateService) {
    const localeFromStorage = this.getFromStorage();
    if (localeFromStorage == null) {
      const browserLang = this.translationService.getBrowserCultureLang();
      this.selectedDateTimeFormat$.next(
        this.allowedLocales.includes(browserLang) ? browserLang : 'en-US'
      );
    } else {
      this.isDefault = false;
      this.selectedDateTimeFormat$.next(localeFromStorage);
    }
  }

  getFromStorage() {
    return localStorage.getItem(this.STORAGEKEY);
  }

  saveToStorage() {
    localStorage.setItem(this.STORAGEKEY , this.selectedDateTimeFormat$.value);
  }
}
