// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select::part(icon) {
  display: none;
}

ion-list {
  padding-top: 10px;
}

ion-icon {
  font-size: 32px;
  margin-right: 10px;
}

.spinner-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-select::part(label) {
  max-width: 75% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/settings/settings.page.scss"],"names":[],"mappings":"AACA;EACI,aAAA;AAAJ;;AAEA;EACI,iBAAA;AACJ;;AAGA;EACI,eAAA;EACA,kBAAA;AAAJ;;AAIA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,+BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADJ;;AAIA;EACI,yBAAA;AADJ","sourcesContent":["\nion-select::part(icon) {\n    display: none;\n}\nion-list {\n    padding-top: 10px;\n}\n\n\nion-icon {\n    font-size: 32px;\n    margin-right: 10px;\n}\n\n\n.spinner-background {\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.15);\n    z-index: 999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\nion-select::part(label) {\n    max-width: 75% !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
