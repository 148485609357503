// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.days-name-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.days-name-container div {
  text-align: center;
  flex: 1;
  font-weight: bold;
  color: gray;
}

ion-text {
  font-weight: bold;
  padding: 5px;
  border-radius: 50%;
  width: 33px;
  text-align: center;
}

.semi-selected {
  color: var(--ion-color-danger);
}

.selected {
  background-color: var(--ion-color-primary);
  color: white;
}

.month-name {
  position: absolute;
  transform: rotate(-90deg);
  left: -12.5px;
  bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  color: gray;
}

h6 {
  margin: 3px 5px;
}

.series-meeting-dot, .meeting-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.meeting-dot {
  background-color: var(--ion-color-medium);
}

.series-meeting-dot {
  background-color: var(--ion-color-warning);
}

.no-meetings {
  opacity: 0.5;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/swiper-calendar/swiper-calendar.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,OAAA;EACA,iBAAA;EACA,WAAA;AAER;;AAEA;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAGA;EACI,0CAAA;EACA,YAAA;AAAJ;;AAEA;EACI,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,UAAA;EACA,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yCAAA;AACJ;;AACA;EACI,0CAAA;AAEJ;;AACA;EACI,YAAA;EACA,oBAAA;AAEJ","sourcesContent":[".days-name-container {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 5px;\n    div {\n        text-align: center;\n        flex: 1;\n        font-weight: bold;\n        color: gray;\n    }\n}\n\nion-text {\n    font-weight: bold;\n    padding: 5px;\n    border-radius: 50%;\n    width: 33px;\n    text-align: center;\n}\n\n.semi-selected {\n    color: var(--ion-color-danger);\n}\n\n\n.selected {\n    background-color: var(--ion-color-primary);\n    color: white;\n}\n.month-name {\n    position: absolute;\n    transform: rotate(-90deg);\n    left: -12.5px;\n    bottom: 5px;\n    font-weight: bold;\n    font-size: 14px;\n    color: gray\n    \n}\nh6 {\n    margin: 3px 5px;\n}\n\n.series-meeting-dot, .meeting-dot {\n    width: 5px;\n    height: 5px;\n    border-radius: 50%;\n}\n\n.meeting-dot {\n    background-color: var(--ion-color-medium);\n}\n.series-meeting-dot {\n    background-color: var(--ion-color-warning);\n}\n\n.no-meetings {\n    opacity: 0.5;\n    pointer-events: none;\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
