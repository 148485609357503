import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-loading-animation',
  templateUrl: './list-loading-animation.component.html',
  styleUrls: ['./list-loading-animation.component.scss'],
})
export class ListLoadingAnimationComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
