// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-header-content {
  background-color: var(--ion-color-danger);
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}

.menu-avatar {
  font-size: 100px;
  margin: 0 auto;
}

.menu-text {
  font-size: 14px;
}

.menu-text:first-of-type {
  padding-top: 5px;
}

.menu-nav-icon {
  font-size: 32px;
}

.menu-nav-icon-1 {
  font-size: 29px;
  padding-left: 1px;
}

ion-content, ion-item {
  --background: var(--ion-color-primary);
}

ion-item {
  --min-height: 85px;
  --border-color: var(--ion-color-dark) ;
}

.menu-thumbnail-wrapper {
  width: 100px;
}

ion-thumbnail {
  --border-radius: 50%;
  --size: 85px;
  margin: 0 auto;
}

ion-header.menu-header {
  background-color: var(--ion-color-danger);
}

.spinner-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filled {
  color: #E0061C;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EACA,yCAAA;EACA,oCAAA;AACJ;;AAEA;EACI,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,sCAAA;AACJ;;AAEA;EACI,kBAAA;EACA,sCAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,oBAAA;EACA,YAAA;EACA,cAAA;AACJ;;AAEA;EACI,yCAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,+BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,cAAA;AACJ","sourcesContent":[".menu-header-content {\n    background-color: var(--ion-color-danger);\n    margin-top: constant(safe-area-inset-top);\n    margin-top: env(safe-area-inset-top);\n}\n\n.menu-avatar {\n    font-size: 100px;\n    margin: 0 auto;\n}\n\n.menu-text {\n    font-size: 14px;    \n}\n\n.menu-text:first-of-type {\n    padding-top: 5px\n}\n\n.menu-nav-icon {\n    font-size: 32px\n}\n\n.menu-nav-icon-1 {\n    font-size: 29px;\n    padding-left: 1px;\n}\n\nion-content, ion-item{\n    --background: var(--ion-color-primary);\n}\n\nion-item {\n    --min-height: 85px;\n    --border-color: var(--ion-color-dark)\n}\n\n.menu-thumbnail-wrapper {\n    width: 100px;\n}\n\nion-thumbnail {\n    --border-radius: 50%;\n    --size: 85px;\n    margin: 0 auto;\n}\n\nion-header.menu-header {\n    background-color: var(--ion-color-danger);\n}\n\n.spinner-background {\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.15);\n    z-index: 999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.filled {\n    color: #E0061C;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
