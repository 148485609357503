import { Component, ComponentFactoryResolver, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';


import { SearchDefaultState, SearchService, SearchState } from './search.service';
import { SearchpopoverComponent } from './searchpopover/searchpopover.component';
import { FloorplanSearchpopoverComponent } from './floorplan-searchpopover/floorplan-searchpopover.component';
import {  FloorplanSearchService, FloorplanSearchState } from './floorplan-search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  
})
export class SearchComponent implements OnInit {
  state: SearchState = new SearchDefaultState();
  searchState: SearchState;
  floorplanState: FloorplanSearchState;
  searchPopover: HTMLIonPopoverElement;
  @Input() disableSearch = true
  constructor(private popoverController: PopoverController, private searchService: SearchService,private floorplanSearchService:FloorplanSearchService,private router:Router) { 

  }

  ngOnInit() {
  
      this.floorplanSearchService.floorplanSearchState$.subscribe(state => {
        this.floorplanState = state
      })
      this.searchService.searchState$.subscribe(state => {
        this.searchState = state
      });

  }
  ionViewDidEnter() {
  
  }
  openSearch(event) {
    if(this.router.url.includes("floor-plan")) {
      this.openFloorplanSearch(event)
    } else {
      this.openBookingSearch(event)
    }
  }
  switchStateColor(){
    let route = this.router.url
    if(route.includes('floor-plan')){
      this.state = this.floorplanState;   
    }
    else{
      this.state = this.searchState
    }
    return this.state.color;
  }

  async openFloorplanSearch(event) {
    if(this.searchPopover != undefined) {
      return
    }
    this.searchPopover = await this.popoverController.create({
      component: FloorplanSearchpopoverComponent,
      event: event,
      cssClass: 'search-popover',
      reference: 'trigger',
      side: 'bottom',
      alignment: 'start',
      mode: 'md',
      componentProps: {initialFloorplanSearchRequest: this.floorplanSearchService.floorplanSearchState.floorplanSearchRequest ?? null}
    })
    
    this.floorplanSearchService.floorplanSearchState$.next(this.floorplanSearchService.floorplanSearchState)
    await this.searchPopover.present().then();
    this.searchPopover.isOpen = true;
    await this.searchPopover.onDidDismiss().then(request => {
      
      this.searchPopover = undefined
      if(request.role == 'apply') {
        this.floorplanSearchService.applySearch(request.data);
      }
      if(request.role == 'reset') {
        this.floorplanSearchService.resetDefaultSearchState();
      }
      if (request.role == 'backdrop') {
        if(this.floorplanSearchService.floorplanSearchState$.getValue().floorplanSearchRequest != null) {
          if (this.floorplanSearchService.floorplanSearchState.floorplanSearchRequest != this.floorplanSearchService.floorplanSearchState$.getValue().floorplanSearchRequest) {
            this.floorplanSearchService.applySearch(this.floorplanSearchService.floorplanSearchState$.getValue().floorplanSearchRequest)
          }
        } else {
          this.floorplanSearchService.resetDefaultSearchStateFromBackdrop();
        }
      }
    })
   
  }

  async openBookingSearch(event: Event) {
    
    if(this.searchPopover != undefined) {
      return
    }
    this.searchPopover = await this.popoverController.create({
      component: SearchpopoverComponent,
      event: event,
      cssClass: 'search-popover',
      reference: 'trigger',
      side: 'bottom',
      alignment: 'end',
      mode: 'md',
      componentProps: {initalSearchRequest: this.searchService.searchState$.getValue().searchRequest}
    })
    
    this.searchService.searchState$.next(this.searchService.searchRequestState)
    await this.searchPopover.present().then();
    this.searchPopover.isOpen = true;
    await this.searchPopover.onDidDismiss().then(request => {
      
      this.searchPopover = undefined
      if(request.role == 'apply') {
        this.searchService.applySearch(request.data);
      }
      //look if we can remove the role
      if(request.role == 'applySeries') {
        this.searchService.applySearch(request.data);
      }
      if(request.role == 'reset') {
        this.searchService.resetDefaultSearchState();
      }
      if (request.role == 'backdrop') {
        if(this.searchService.searchState$.getValue().searchRequest != null) {
          if (this.searchService.searchRequestState.searchRequest != this.searchService.searchState$.getValue().searchRequest) {
            this.searchService.applySearch(this.searchService.searchState$.getValue().searchRequest)
          }
          
            
        } else {
          this.searchService.resetDefaultSearchStateFromBackdrop();
        }
      }
    })
   
  }




}
