import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ViewDidEnter } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DatetimeFormatService } from 'src/app/services/datetime-format-service/datetime-format.service';
import { PlacesService } from 'src/app/services/placesService/places.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-swiper-calendar',
  templateUrl: './swiper-calendar.component.html',
  styleUrls: ['./swiper-calendar.component.scss'],
})
export class SwiperCalendarComponent implements OnInit {
  slides = []
  daysNames = []
  dateTimeFormat: string 
  didHighlightDateOnFirstRender = false
  @ViewChild('swiper') swiperRef: ElementRef<SwiperContainer>;
  @Output() scrollToSection = new EventEmitter<any>() 
  @Input() selectedSegment: string


  constructor(private placesService: PlacesService, private dateTimeService : DatetimeFormatService) {}
    
  ngOnInit() {
      this.placesService.MyBooking$.subscribe(bookings => {
        this.disableSlidesBasedOnMeetings(bookings,this.selectedSegment)
      })
     this.dateTimeService.selectedDateTimeFormat$.subscribe(format => {
        this.dateTimeFormat = format
        this.generateSlides()  
      })
      
  }
  

  generateSlides() {
    this.slides = []
    this.daysNames = []
    const today = new Date();
    
    // Find the closest Monday
    const closestMonday = new Date(today);
    const dayOfWeek = today.getDay();
    const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // If Sunday, go back 6 days, otherwise go to previous Monday
    closestMonday.setDate(today.getDate() + diff);
    
    for (let i = 0; i < 35; i++) { // Generate 5 weeks (35 days) of slides
      const currentDate = new Date(closestMonday);
      currentDate.setDate(closestMonday.getDate() + i);
      
      const dayName = currentDate.toLocaleDateString(this.dateTimeFormat, { weekday: 'short' });
      if(this.daysNames.length < 7) {
        this.daysNames.push(dayName)
      }
      const dayNumber = currentDate.getDate();
      const monthNumber = currentDate.getMonth();
      const href = moment(currentDate).format('YYYY-MM-DD')
      const monthName = currentDate.toLocaleString(this.dateTimeFormat, { month: 'short' });
      this.slides.push({
        dayName: dayName,
        monthName: monthName,
        dayNumber: dayNumber,
        monthNumber :  monthNumber,
        isSelected: false,
        isSemiSelected: today.getDate() == dayNumber && today.getMonth() == monthNumber,
        href: href,
        hasMeetings: undefined,
        hasSeriesMeetings: undefined
      });
    }
    
  }

  disableSlidesBasedOnMeetings(bookings: any[],selectedSegment?: string) {
  // mapping bookings to sliders to be able to disable sliders if no meeting was found
    bookings.forEach((booking) => {
      const startDate = new Date(booking.startTime);
      const dateKey = moment(startDate).format('YYYY-MM-DD')
      
      if(selectedSegment == 'mine' ) {
        const slideIndex = this.slides.findIndex(slide => slide.href === dateKey  && booking.isOrganizer);
        if (slideIndex !== -1) {
          this.slides[slideIndex].hasMeetings = true;

        }
   
      } else if (selectedSegment == 'invitations') {
        const slideIndex = this.slides.findIndex(slide => slide.href === dateKey && !booking.isOrganizer) ;
        if(slideIndex !== -1) {
          this.slides[slideIndex].hasInvitations = true;
        }
      }
    });
    // for the first render of bookings page , no slide is highlighted due to no scroll have been made yet. this is what we doing here
    if(this.didHighlightDateOnFirstRender == false) {
      if (selectedSegment === 'mine') {
        const firstMeetingSlideIndex = this.slides.findIndex(slide => slide.hasMeetings);
        if (firstMeetingSlideIndex !== -1) {
          this.slides[firstMeetingSlideIndex].isSelected = true;
          this.didHighlightDateOnFirstRender = true

        }
      } else {
        const firstInvitationSlideIndex = this.slides.findIndex(slide => slide.hasInvitations);
        if (firstInvitationSlideIndex !== -1) {
          this.slides[firstInvitationSlideIndex].isSelected = true;
          this.didHighlightDateOnFirstRender = true
        }
      }
    }
    if(this.placesService.fetchMyBookingsIsFinished) this.didHighlightDateOnFirstRender = false

  }  



  highlightDate(dateId: string,shouldAnimate = false) {
    const index = this.slides.findIndex(slide => slide.href === dateId);
    if (index !== -1) {
      this.slides.forEach((slide) => slide.isSelected = false);
      this.slides[index].isSelected = true;

      // Ensure the swiper is initialized
      if (this.swiperRef && this.swiperRef.nativeElement && this.swiperRef.nativeElement.swiper) {
        const swiper = this.swiperRef.nativeElement.swiper;
        
        // Get the current visible slides range
        const startIndex = swiper.activeIndex;
        const endIndex = startIndex + +swiper.params?.slidesPerView - 1;

        // Check if the highlighted date is outside the visible range
        if (index < startIndex || index > endIndex) {
          // Calculate the target slide index
          let targetSlide = Math.floor(index / 7) * 7; // Align to the start of the week
          
          // Ensure the target slide is within bounds
          targetSlide = Math.max(0, Math.min(targetSlide, this.slides.length - +swiper.params.slidesPerView));
          
          // Slide to the target slide immediately without animation
          swiper.slideTo(targetSlide, shouldAnimate ? 250 : 0, false);
        }
      }
    } 
  }

  chooseDate(date) {
    this.scrollToSection.emit(date);
    setTimeout(() => {
      this.slides.forEach((slide) => slide.isSelected = false);
      date.isSelected = true
    }, 255);
  }
}
