import { Component, OnInit ,OnDestroy} from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FloorPlanInfo } from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api-services/api.service';
import { FloorplanSearchRequestState, FloorplanSearchService } from '../floorplan-search.service';
import * as moment from 'moment';
import { ToastService } from 'src/app/services/toast-service/toast.service';

@Component({
  selector: 'app-floorplan-searchpopover',
  templateUrl: './floorplan-searchpopover.component.html',
  styleUrls: ['./floorplan-searchpopover.component.scss'],
})
export class FloorplanSearchpopoverComponent implements OnInit {
  selectedCountry: string 
  selectedCity: string 
  selectedStreet: string 
  selectedLabel: string 
  selectedFloor: Number 
  countries: string[] = [];
  citys: string[] = [];
  streets: string[] = [];
  labels: string[] = [];
  floors: number[] = [];
  floorList: FloorPlanInfo[] = [];
  isLoading : boolean = false
  initialFloorplanSearchRequest :FloorPlanInfo
  desiredFloorPlan:FloorPlanInfo
  constructor(
    private apiService: ApiService,
    public toastService: ToastService,
    public translateService: TranslateService,
    private popoverController: PopoverController,
    private floorplanSearchService:FloorplanSearchService
    ) { }

  ngOnInit() {
    this.floorplanSearchService.floorplanInfoListLoading$.subscribe(res=>this.isLoading = res)
    this.floorplanSearchService.floorplanInfoList$.subscribe( res => {
    this.resetSelectionAndOptions() 
    this.floorList = res

    if(this.floorList.length > 0) {
      this.floorList.forEach(floor => {
        this.countries.push(floor.country)
      })
      
      if(this.initialFloorplanSearchRequest != null) {
        this.countries = Array.from(new Set(this.countries))
        this.selectedCountry = this.initialFloorplanSearchRequest.country
        this.filterCity()
        this.selectedCity = this.initialFloorplanSearchRequest.city
        this.filterStreet()
        this.selectedStreet = this.initialFloorplanSearchRequest.street 
        this.filterLabel()
        this.selectedLabel = this.initialFloorplanSearchRequest.floorLabel 
        this.filterFloor()
        this.selectedFloor = this.initialFloorplanSearchRequest.floor 
      } else {
        
        this.countries = Array.from(new Set(this.countries))
        if(this.countries.length == 1) {
          this.selectedStreet = ""
          this.selectedLabel = ""
          this.selectedFloor = null
          this.selectedCountry = this.countries[0]
          this.filterCity()
        }
      }
    }
      
    }) 
      
  }

  ionViewDidEnter(): void {
  }
 
  resetSelectionAndOptions() {
    this.selectedCountry =  ""
    this.selectedCity =  ""
    this.selectedStreet =  ""
    this.selectedLabel =  ""
    this.selectedFloor = null
    this.countries = [] 
    this.citys = [] 
    this.streets = [] 
    this.labels = [] 
    this.floors = [];

  }

  filterStreet() {  
    this.labels = []
    this.floors = []
    this.streets = []
    this.selectedStreet = ""
    this.selectedLabel = ""
    this.selectedFloor = null
    let filterCitySelect = this.floorList.filter(floorPlan => floorPlan.city == this.selectedCity 
    && floorPlan.country == this.selectedCountry)
    filterCitySelect.forEach(floorPlan => {
      this.streets.push(floorPlan.street)
    })
    this.streets = Array.from(new Set(this.streets));
    if (this.streets.length == 1 ) {
      this.selectedStreet = this.streets[0];
      this.filterLabel()
    }
    }
  
  
  filterLabel() {
    this.floors = []
    this.labels = []
    this.selectedLabel = ""
    this.selectedFloor = null
    let filterStreetSelect = this.floorList.filter(floorPlan => floorPlan.street == this.selectedStreet 
    && floorPlan.country == this.selectedCountry 
    && floorPlan.city == this.selectedCity) 
    filterStreetSelect.forEach(floorPlan => {
      this.labels.push(floorPlan.floorLabel)
    })
    
    this.labels = Array.from(new Set(this.labels));
    if (this.labels.length == 1 ) {
      this.selectedLabel = this.labels[0];
      this.filterFloor()
    }
  }
  
  filterFloor() {
    this.selectedFloor = null
    this.floors = []
    let filterLabelSelect = this.floorList.filter(floorPlan => floorPlan.floorLabel == this.selectedLabel
    && floorPlan.street == this.selectedStreet 
    && floorPlan.country == this.selectedCountry 
    && floorPlan.city == this.selectedCity)
    filterLabelSelect.forEach(floorPlan => {
      this.floors.push(floorPlan.floor)
    })
    this.floors = Array.from(new Set(this.floors));
    if (this.floors.length == 1 ) {
      this.selectedFloor = this.floors[0];
    }
  }
  filterCity() {
    this.streets = []
    this.labels = []
    this.floors = []
    this.citys = []
    this.selectedCity = ""
    this.selectedStreet = ""
    this.selectedLabel = ""
    this.selectedFloor = null
    let filterCountrySelect = this.floorList.filter(floorPlan => floorPlan.country == this.selectedCountry)
    filterCountrySelect.forEach(floorPlan => {
      this.citys.push(floorPlan.city)
    })
    this.citys = Array.from(new Set(this.citys))
    if (this.citys.length == 1 ) {
      this.selectedCity = this.citys[0];
      this.filterStreet()
     }
    }

  private getFloorPlanId() {
    for (let floor of this.floorList) {
      if (floor.country == this.selectedCountry
        && floor.city == this.selectedCity
        && floor.street == this.selectedStreet
        && floor.floorLabel == this.selectedLabel
        && floor.floor == this.selectedFloor) {
        return floor;
      }
    }
    console.log("No ID found: ", null);
    return null;
  }


  applySearch() {
    this.isLoading = true
    this.desiredFloorPlan = this.getFloorPlanId();
    if(this.desiredFloorPlan.floorMapImage == null) return this.noFloorplanImageFoundToast();
    this.isLoading = false
    this.popoverController.dismiss(this.desiredFloorPlan, 'apply')
  }

  async noFloorplanImageFoundToast() {
    this.isLoading = false
    await this.toastService.openActionToastAsync({
      message: "noFloorplanImageFound",
      duration: 10000,
      color: 'danger',
      cssClass: 'custom-toast-button',
      layout: 'stacked',
      buttons: [
            {
              text: this.translateService.instant("refreshFloorplans"),
              icon:"sync",
              handler: () => {
                this.isLoading=true
                this.floorplanSearchService.getFloorplanInfoList();
                setTimeout(()=> this.isLoading=false, 2000)
              }
            }
          ]
    });
  } 


  resetSearch() {
    this.popoverController.dismiss(null, 'reset')
  }
}
