import { Injectable } from '@angular/core';
import { LocalNotifications, ScheduleOptions } from '@capacitor/local-notifications';
import { ToastService } from '../toast-service/toast.service';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {

  constructor(toastService: ToastService) { }

  async presentLocalNotification(message,title){
    const granted = await LocalNotifications.requestPermissions();
    if (granted && granted.display != "denied") {
      console.log('Notification permission granted');
      let options:ScheduleOptions={
        notifications:[
          {
            id:1,
            title:title,
            body:message,
            smallIcon:'notification_logo',
            iconColor:'#000000'
          }
        ]
      }
      try{
        await LocalNotifications.schedule(options);
        return true;
      }
      catch (ex){
        console.log(ex);
        return false;
      }
    } else {
      console.log('Notification permission denied');
      return false;
    }   
  }
}
