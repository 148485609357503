import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  DATETIMEFORMART,
  UserInformation,
  WorkingHours,
} from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api-services/api.service';
import { DatetimeFormatService } from 'src/app/services/datetime-format-service/datetime-format.service';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
})
export class WorkingHoursComponent implements OnInit {
  workingHours: WorkingHours = null;
  workStartTime: string;
  workEndTime: string;
  isOpenAlert: boolean = false;
  message = '';
  alertButtons = [
    {
      text: 'Microsoft Working Hours',
      role: 'confirm',
      handler: () => {
        //todo
        this.isOpenAlert = false;
        const setMicrosoftWorkingHoursSupportLink =
          'https://support.microsoft.com/en-us/office/set-your-work-hours-and-location-in-outlook-af2fddf9-249e-4710-9c95-5911edfd76f6#:~:text=Select%20Settings%20%3E%20Calendar%20%3E%20Work%20hours,where%20you%27ll%20be%20sitting';
        this.openBrowser(setMicrosoftWorkingHoursSupportLink);
      },
    },
    {
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
        this.isOpenAlert = false;
      },
    },
  ];
  weekdays: { value: string; active: boolean; stringName: string }[] = [
    { value: 'Mon', active: false, stringName: 'weekdayName.Mon' },
    { value: 'Tue', active: false, stringName: 'weekdayName.Tue' },
    { value: 'Wed', active: false, stringName: 'weekdayName.Wed' },
    { value: 'Thu', active: false, stringName: 'weekdayName.Thu' },
    { value: 'Fri', active: false, stringName: 'weekdayName.Fri' },
    { value: 'Sat', active: false, stringName: 'weekdayName.Sat' },
    { value: 'Sun', active: false, stringName: 'weekdayName.Sun' },
  ];

  constructor(
    private apiService: ApiService,
    public translateService: TranslateService,
    private modalController: ModalController,
    private router: Router,
    public dateTimeFormatService: DatetimeFormatService
  ) {
    this.apiService.userInformation$.subscribe(
      (userInformation: UserInformation) => {
        this.workingHours = userInformation?.workingHours;
        if (this.workingHours != null) {
          this.workStartTime = moment()
            .set('hours', this.workingHours.startTime.hour)
            .set('minutes', this.workingHours.startTime.minute)
            .set('second', 0)
            .format(DATETIMEFORMART);
          this.workEndTime = moment()
            .set('hours', this.workingHours.endTime.hour)
            .set('minutes', this.workingHours.endTime.minute)
            .set('second', 0)
            .format(DATETIMEFORMART);
          this.weekdays.forEach(
            (weekday, index) =>
              (weekday.active = this.workingHours.daysOfWeek.includes(index + 1)
                ? true
                : false)
          );
        }
      }
    );
  }

  ngOnInit() {}

  async presentAlert(state) {
    if (this.workingHours) {
      this.message = this.translateService.instant(
        'workingHours.workingHoursAlertMessage'
      );
      this.isOpenAlert = state;
    }
  }

  async openBrowser(url: string) {
    await Browser.open({ url: url });
    Browser.addListener('browserFinished', () => {
      this.router.navigateByUrl('/tabs/favorite');
    });
  }
}
