import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ActionSheetController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackService {
  navigationUrls: string[] = [];
  navigationDepth: number = 0;
  showAreYouSureModal: boolean = false;

  constructor(private navController: NavController,
    private actionSheetController: ActionSheetController,
    private translateService: TranslateService,
  ) {
  }
  
  ngOnInit() {
  }
  public navigateBack(){
    if(this.navigationUrls.length == 1){
      this.navController.navigateRoot('/tabs/favorite')
    }
    else{
      --this.navigationDepth;
      this.navigationUrls.pop();
      this.navController.navigateRoot(this.navigationUrls[this.navigationDepth]);
    }
    this.showAreYouSureModal = false;
  }
  async goBackToPrevious() {
    if(this.showAreYouSureModal)
      {  
        const actionSheet = await this.actionSheetController.create({
          header: this.translateService.instant('navigationInfo'),
          mode: 'ios',
          buttons: [
            {
              text: this.translateService.instant('continueEditing'),
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
            {
              text: this.translateService.instant('cancelChanges'),
              role: 'destructive',
              data: {
                action: 'continue',
              },
            },
          ]
    
        });
    
        await actionSheet.present();
        const condition = await actionSheet.onWillDismiss();
    
    
        if (condition.role == 'destructive') {
          this.navigateBack();
        }
      }
      else
      {
        this.navigateBack();
      }
    
  }
}
