import { Injectable, Injector } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { ApiService } from './api-services/api.service';
import { environment } from 'src/environments/environment';
import { CalendarEvent, Place, SearchRequest } from '../interfaces/interfaces';
import * as moment from 'moment';
import { AvatarService } from './avatarService/avatar.service';
import { FloorplanSearchService } from '../shared/search/floorplan-search.service';
import { PlacesService } from './placesService/places.service';
import { AuthService } from './authService/auth.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private connection: signalR.HubConnection;
  private MyBookingsConnection: signalR.HubConnection;
  public places$ = new BehaviorSubject<Place[]>([]);
  public searchPlaces$ = new Subject<any>();
  public events$ = new BehaviorSubject<CalendarEvent[]>([]);
  public favoritePlaces$ = new Subject<any>();
  public searchFavoritePlaces$ = new Subject<any>();
  public NotFavoritePlcaes$ = new Subject<any>();
  public errorMessage$ = new Subject<any>();
  public loading$ = new BehaviorSubject<boolean>(false);
  public isMyBookingFinished$ = new BehaviorSubject<boolean>(true);
  public isInvitationsFinished$ = new BehaviorSubject<boolean>(true);
  public myBookingError$ = new BehaviorSubject<boolean>(false);
  public placesTemp: Place[] = [];
  public AvailabePlaces$ = new Subject<any>();
  private placesService: PlacesService;
  public isRedisChached: boolean;
  public showProgressBarForBookings$ = new BehaviorSubject<boolean>(false);
  public myBookingsChunksCounter: number;
  public invitationsChunksCounter: number;
  constructor(
    private injector: Injector,
    private avatarService: AvatarService,
    private floorplanSearchService: FloorplanSearchService,
    private authService: AuthService
  ) { }
  //before each websocket call we should use the interceptor function of fetchValidToken after making it a service
  public async createConnectionAndInvokeGetCall(invokeName: string) {
    const token = await this.authService.fetchValidToken();
    this.connection = await new signalR.HubConnectionBuilder()
      .withUrl(environment.baseUrl + '/SparoConnectionHub', {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();
    this.connection.start().then(() => {
      console.log('connection started');
      //calling the API function
      this.connection
        .invoke(invokeName)
        .catch((err) => console.log('invokation canceled'));
      this.errorListener();
    });
  }
  public async createConnectionAndInvokeGetMyEvents(invokeName: string) {
    const token = await this.authService.fetchValidToken();
    this.MyBookingsConnection = await new signalR.HubConnectionBuilder()
      .withUrl(environment.baseUrl + '/SparoConnectionHub', {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();
    this.MyBookingsConnection.start().then(() => {
      console.log('connection started');
      //calling the API function
      this.MyBookingsConnection.invoke(invokeName).catch((err) =>
        console.log('invokation canceled')
      );
      this.MyBookingsConnectionErrorListener();
    });
  }
  public async createConnectionAndInvokeGetCallWithParmetar(
    invokeName: string,
    param: string,
    param2?: any
  ) {
    const token = await this.authService.fetchValidToken();
    this.connection = await new signalR.HubConnectionBuilder()
      .withUrl(environment.baseUrl + '/SparoConnectionHub', {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();
    this.connection.start().then(() => {
      console.log('connection started');
      //calling the API function
      this.connection
        .invoke(invokeName, param, param2)
        .catch((err) => console.log('invokation canceled'));
      this.errorListener();
    });
  }

  public async createConnectionAndInvokeSeriesSearchCall(
    invokeName: string,
    startDate,
    endDate,
    placesType,
    AccuranceDays,
    interval,
    dailyType,
    choosenDate,
    reccuranceEndDate,
    isFavoritePlaces: boolean
  ) {
    const token = await this.authService.fetchValidToken();
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.baseUrl + '/SparoConnectionHub', {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();
    this.connection.start().then(() => {
      console.log('connection started');
      //calling the API function
      this.connection
        .invoke(
          invokeName,
          startDate,
          endDate,
          placesType,
          AccuranceDays,
          interval,
          dailyType,
          choosenDate,
          reccuranceEndDate,
          isFavoritePlaces
        )
        .catch((err) => console.log('invokation canceled'));
      this.errorListener();
    });
  }

  searchAvailableOrFavoritePlacesForSeriesEvent(
    seriesSearchRequest: SearchRequest,
    isFavoritePlaces: boolean
  ) {
    let reccuranceEndDate = seriesSearchRequest.recurrenceEndDate;
    let repeatEvery = seriesSearchRequest.repeatEvery;
    let selectedWeekDays = seriesSearchRequest.selectedWeekDays;
    let startTimeUTC = seriesSearchRequest.startTimeUTC;
    let endTimeUTC = seriesSearchRequest.endTimeUTC;
    let placeType = seriesSearchRequest.type;
    let recurrenceType = seriesSearchRequest.recurrenceType;
    let dayOfMonthlyMeeting = moment(seriesSearchRequest.startTimeUTC)
      .set('date', seriesSearchRequest.dayOfMonthlyMeeting)
      .toISOString();

    this.placesTemp = [];
    if (this.connection != undefined) {
      //to stop the connection if the previous call didn't finish
      if (this.connection.state == signalR.HubConnectionState.Connected) {
        this.connection
          .stop()
          .then(() =>
            console.log(
              'connection stopped because the previous function did not finish'
            )
          );
      }
    }

    //Creating connection and invoking getAvailablePlace
    this.createConnectionAndInvokeSeriesSearchCall(
      'SearchAvailableOrFavoritePlacesForSeriesEvent',
      startTimeUTC,
      endTimeUTC,
      placeType,
      selectedWeekDays,
      repeatEvery,
      recurrenceType,
      dayOfMonthlyMeeting,
      reccuranceEndDate,
      isFavoritePlaces
    ).then(() => {
      this.loading$.next(true);
      //start listening for "AvailablePlaces"
      if (isFavoritePlaces) {
        this.connection.on('SearchFavoritePlaces', (data) => {
          if (data != 'finish') {
            data.forEach((element) => {
              this.placesTemp.push(element);
            });
            this.loading$.next(true);
            this.placesTemp =
              this.floorplanSearchService.mapPlacesListToItsFloorMap(
                this.placesTemp
              );
            this.searchFavoritePlaces$.next(
              this.avatarService.mapAvatarListToPlaceList(this.placesTemp)
            );
          } else {
            this.loading$.next(false);
            this.connection
              .stop()
              .then(() =>
                console.log('connection stopped after finishing the call')
              );
          }
        });
      } else {
        this.connection.on('SearchAvailablePlaces', (data) => {
          if (data != 'finish') {
            data.forEach((element) => {
              this.placesTemp.push(element);
            });
            this.loading$.next(true);
            this.placesTemp =
              this.floorplanSearchService.mapPlacesListToItsFloorMap(
                this.placesTemp
              );
            let mappedPlaces = this.avatarService.mapAvatarListToPlaceList(
              this.placesTemp
            );
            this.searchPlaces$.next(mappedPlaces);
            this.placesService.unfilteredPlaces$.next(mappedPlaces);
          } else {
            this.loading$.next(false);
            this.connection
              .stop()
              .then(() =>
                console.log('connection stopped after finishing the call')
              );
          }
        });
      }
    });

  }

  public async createConnectionAndInvokeSearchCall(
    invokeName: string,
    startTimeUTC,
    endTimeUTC,
    type
  ) {
    const token = await this.authService.fetchValidToken();
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.baseUrl + '/SparoConnectionHub', {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();
    this.connection.start().then(() => {
      console.log('connection started');
      //calling the API function
      this.connection
        .invoke(invokeName, startTimeUTC, endTimeUTC, type)
        .catch((err) => console.log('invokation canceled'));
      this.errorListener();
    });
  }

  public getAvailablePlaces() {
    this.placesTemp = [];
    if (this.connection != undefined) {
      //to stop the connection if the previous call didn't finish
      if (this.connection.state == signalR.HubConnectionState.Connected) {
        this.connection
          .stop()
          .then(() =>
            console.log(
              'connection stopped because the previous function did not finish'
            )
          );
      }
    }
    //Creating connection and invoking getAvailablePlace
    this.createConnectionAndInvokeGetCall('getAvailablePlace').then(() => {
      this.loading$.next(true);
      //start listening for "AvailablePlaces"
      this.connection.on('AvailablePlaces', (data) => {
        if (data != 'finish') {
          data.forEach((element) => {
            this.placesTemp.push(element);
          });
          this.loading$.next(true);
          this.placesTemp =
            this.floorplanSearchService.mapPlacesListToItsFloorMap(
              this.placesTemp
            );
          this.places$.next(
            this.avatarService.mapAvatarListToPlaceList(this.placesTemp)
          );
        } else {
          this.loading$.next(false);
          this.connection.stop().then(() => {
            console.log('connection stopped after finishing the call');
          });
        }
      });
    });

  }

  public searchAvailablePlaces(searchRequest) {
    this.placesTemp = [];
    this.searchPlaces$.next([]);
    if (this.connection != undefined) {
      //to stop the connection if the previous call didn't finish
      if (this.connection.state == signalR.HubConnectionState.Connected) {
        this.connection
          .stop()
          .then(() =>
            console.log(
              'connection stopped because the previous function did not finish'
            )
          );
      }
    }
    //Creating connection and invoking searchAvailablePlace
    this.createConnectionAndInvokeSearchCall(
      'SearchAvailablePlaces',
      searchRequest.startTimeUTC,
      searchRequest.endTimeUTC,
      searchRequest.type
    ).then(() => {
      this.loading$.next(true);
      //start listening for SearchAvailablePlaces
      this.connection.on('SearchAvailablePlaces', (data) => {
        if (data != 'finish') {
          data.forEach((element) => {
            this.placesTemp.push(element);
          });
          this.loading$.next(true);
          this.placesTemp =
            this.floorplanSearchService.mapPlacesListToItsFloorMap(
              this.placesTemp
            );
          this.placesTemp = this.avatarService.mapAvatarListToPlaceList(
            this.placesTemp
          );
          this.searchPlaces$.next(this.placesTemp);
          this.places$.next(this.placesTemp);
          this.placesService.unfilteredPlaces$.next(this.placesTemp);
        } else {
          this.loading$.next(false);
          this.connection
            .stop()
            .then(() =>
              console.log('connection stopped after finishing the call')
            );
        }
      });
    });

  }

  public getFavoritePlaces() {
    this.placesTemp = [];
    if (this.connection != undefined) {
      //to stop the connection if the previous call didn't finish
      if (this.connection.state == signalR.HubConnectionState.Connected) {
        this.connection
          .stop()
          .then(() =>
            console.log(
              'connection stopped because the previous function did not finish'
            )
          );
      }
    }

    //Creating connection
    this.createConnectionAndInvokeGetCall('GetFavoritePlaces').then(() => {
      this.loading$.next(true);
      //start listening and invoking FavoritePlaces
      this.connection.on('FavoritePlaces', (data) => {
        if (data != 'finish') {
          data.forEach((element) => {
            this.placesTemp.push(element);
          });
          this.loading$.next(true);
          this.placesTemp =
            this.floorplanSearchService.mapPlacesListToItsFloorMap(
              this.placesTemp
            );
          this.favoritePlaces$.next(
            this.avatarService.mapAvatarListToPlaceList(this.placesTemp)
          );
        } else {
          this.loading$.next(false);
          this.connection
            .stop()
            .then(() =>
              console.log('connection stopped after finishing the call')
            );
        }
      });
    });

  }

  public getAvaialbePlacesNew(isFavorite, isForceRefresh = false) {
    this.placesService = this.injector.get(PlacesService);
    this.placesTemp = [];
    if (this.connection != undefined) {
      //to stop the connection if the previous call didn't finish
      if (this.connection.state == signalR.HubConnectionState.Connected) {
        this.connection
          .stop()
          .then(() =>
            console.log(
              'connection stopped because the previous function did not finish'
            )
          );
      }
    }
    //Creating connection
    this.createConnectionAndInvokeGetCallWithParmetar(
      'GetAvailablePlacesWithCacheAndForceRefresh',
      isFavorite,
      isForceRefresh
    ).then(() => {
      this.placesTemp = [];

      this.loading$.next(true);
      //start listening and invoking FavoritePlaces
      this.connection.on('FavoritePlaces', (data) => {
        if (data != 'finish') {
          data.forEach((element) => {
            this.placesTemp.push(element);
          });
          this.loading$.next(true);
          this.placesTemp =
            this.floorplanSearchService.mapPlacesListToItsFloorMap(
              this.placesTemp
            );
          this.AvailabePlaces$.next(
            this.avatarService.mapAvatarListToPlaceList(this.placesTemp)
          );
        } else {
          this.placesService.lastupdateFavorite = moment();
          this.placesService.fetchFavoriteIsFinished = true;
          this.loading$.next(false);
          this.connection
            .stop()
            .then(() =>
              console.log('connection stopped after finishing the call')
            );
        }
      });
      this.connection.on('AvailablePlaces', (data) => {
        if (data != 'finish') {
          data.forEach((element) => {
            this.placesTemp.push(element);
          });
          this.loading$.next(true);
          this.placesTemp =
            this.floorplanSearchService.mapPlacesListToItsFloorMap(
              this.placesTemp
            );
          let mappedPlaces = this.avatarService.mapAvatarListToPlaceList(
            this.placesTemp
          );
          this.AvailabePlaces$.next(mappedPlaces);
          this.placesService.unfilteredPlaces$.next(mappedPlaces);
          console.log(mappedPlaces);
        } else {
          this.placesService.lastupdatePlaces = moment();
          this.placesService.fetchAvaialbeIsFinished = true;
          this.loading$.next(false);
          this.connection.stop().then(() => {
            console.log('connection stopped after finishing the call');
          });
        }
      });
      this.connection.on('AvailablePlacesCached', (data) => {
        this.isRedisChached = true;
        if (data != 'finish') {
          data.forEach((element) => {
            this.placesTemp.push(element);
          });
          this.loading$.next(true);
          this.placesTemp =
            this.floorplanSearchService.mapPlacesListToItsFloorMap(
              this.placesTemp
            );
          let mappedPlaces = this.avatarService.mapAvatarListToPlaceList(
            this.placesTemp
          );
          this.AvailabePlaces$.next(mappedPlaces);
          this.placesService.unfilteredPlaces$.next(mappedPlaces);
          console.log(mappedPlaces);
        } else {
          this.placesService.lastupdatePlaces = moment();
          this.placesService.fetchAvaialbeIsFinished = true;
          this.placesService.lastupdateFavorite = moment();
          this.placesService.fetchFavoriteIsFinished = true;
          this.loading$.next(false);
          this.connection.stop().then(() => {
            console.log('connection stopped after finishing the call');
          });
          this.isRedisChached = false;
        }
      });
    });

  }
  public searchFavoritePlaces(searchRequest) {
    this.placesTemp = [];
    if (this.connection != undefined) {
      //to stop the connection if the previous call didn't finish
      if (this.connection.state == signalR.HubConnectionState.Connected) {
        this.connection
          .stop()
          .then(() =>
            console.log(
              'connection stopped because the previous function did not finish'
            )
          );
      }
    }
    //Creating connection and invoking SearchFavoriteAvailablePlaces
    this.createConnectionAndInvokeSearchCall(
      'SearchFavoriteAvailablePlaces',
      searchRequest.startTimeUTC,
      searchRequest.endTimeUTC,
      searchRequest.type
    ).then(() => {
      //start listening for SearchFavoritePlaces
      this.connection.on('SearchFavoritePlaces', (data) => {
        if (data != 'finish') {
          data.forEach((element) => {
            this.placesTemp.push(element);
          });
          this.placesTemp =
            this.floorplanSearchService.mapPlacesListToItsFloorMap(
              this.placesTemp
            );
          this.places$.next(
            this.avatarService.mapAvatarListToPlaceList(this.placesTemp)
          );
          this.loading$.next(true);
          this.searchFavoritePlaces$.next(this.placesTemp);
        } else {
          this.loading$.next(false);
          this.connection
            .stop()
            .then(() =>
              console.log('connection stopped after finishing the call')
            );
        }
      });
    });


  }

  public GetNotFavoritePlaces() {
    this.placesTemp = [];
    if (this.connection != undefined) {
      //to stop the connection if the previous call didn't finish
      if (this.connection.state == signalR.HubConnectionState.Connected) {
        this.connection
          .stop()
          .then(() =>
            console.log(
              'connection stopped because the previous function did not finish'
            )
          );
      }
    }
    //Creating connection and invoking getAvailablePlace
    this.createConnectionAndInvokeGetCall('GetNotFavoritePlaces').then(() => {
      this.loading$.next(true);
      //start listening for "AvailablePlaces"
      this.connection.on('NotFavoritePlaces', (data) => {
        this.NotFavoritePlcaes$.next(
          this.avatarService.mapAvatarListToPlaceList(data)
        );
        this.loading$.next(false);
        this.connection
          .stop()
          .then(() => console.log('connection stopped after finishing the call'));
      });
    });

  }

  public GetEventsAndSaveToLocalStorge() {
    this.myBookingError$.next(false);
    this.showProgressBarForBookings$.next(true);
    let isLocalCached = this.checkIfLocalStorgeExist('myBookings');
    this.placesService = this.injector.get(PlacesService);
    if (
      this.MyBookingsConnection?.state == signalR.HubConnectionState.Connected
    ) {
      return;
    }
    let fetchedEvents: CalendarEvent[] = [];
    this.myBookingsChunksCounter = 0;
    this.invitationsChunksCounter = 0;
    //Creating connection and invoking GetEvents
    this.myBookingsChunksCounter = 0;
    this.invitationsChunksCounter = 0;
    this.createConnectionAndInvokeGetMyEvents('GetEvents').then(() => {
      this.loading$.next(true);
      !isLocalCached && this.isMyBookingFinished$.next(false);
      !isLocalCached && this.isInvitationsFinished$.next(false);
      //start listening for "Events" Call Back
      this.MyBookingsConnection.on('Events', (data) => {
        if (data == 'finish') {
          this.loading$.next(false);
          this.showProgressBarForBookings$.next(false);
          this.MyBookingsConnection.stop().then(() =>
            console.log('connection stopped after finishing the call')
          );
          this.placesService.lastupdateMyBooking = moment();
          this.placesService.fetchMyBookingsIsFinished = true;
          this.saveToLocalStorge('myBookings', this.events$.value);
          this.placesService.MyBooking$.next(this.events$.value);
          this.myBookingsChunksCounter = 0;
          this.invitationsChunksCounter = 0;
          return;
        }
        if (data == 'myBookings') {
          ++this.myBookingsChunksCounter == 7 || isLocalCached
            ? this.isMyBookingFinished$.next(true)
            : this.isMyBookingFinished$.next(false);
        }

        if (data == 'invitations') {
          ++this.invitationsChunksCounter == 7 || isLocalCached
            ? this.isInvitationsFinished$.next(true)
            : this.isInvitationsFinished$.next(false);
        }
        data = this.avatarService.mapAvatarListToEventList(data);
        this.placesService.syncMyBookingData(data);
        data.forEach((element) => {
          fetchedEvents.push(element);
        });
        this.events$.next(fetchedEvents);
      });
    });

  }
  public saveToLocalStorge(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  public checkIfLocalStorgeExist(key) {
    let localData = localStorage.getItem(key);
    return localData == null ? false : true;
  }
  public errorListener() {
    this.connection.on('ErrorCallBack', (error) => {
      this.connection
        .stop()
        .then((err) =>
          console.log('there was an error in the server side:', error)
        );
      this.errorMessage$.next(error);
    });
  }
  public MyBookingsConnectionErrorListener() {
    this.MyBookingsConnection.on('ErrorCallBack', (error) => {
      this.MyBookingsConnection.stop().then((err) =>
        console.log(
          'there was an error in the server side with fetching the event:',
          error
        )
      );
      this.myBookingError$.next(error);
    });
  }

  public stopAllListener() {
    console.log('stop All listener');
    this.connection.off('AvailablePlaces');
    this.connection.off('SearchAvailablePlaces');
    this.connection.off('FavoritePlaces');
    this.connection.off('SearchFavoritePlaces');
  }

  public stopConnection() {
    if (this.connection?.state == 'Connected') {
      this.connection.stop();
    }
  }
}
