// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  margin-bottom: 15px;
  margin-right: 25px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 8px;
  border: 2px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.small {
  width: 10px;
  height: 10px;
  margin-bottom: 12px;
  margin-right: 16px;
}

.small div {
  width: 10px;
  height: 10px;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/item-loading/item-loading.component.scss"],"names":[],"mappings":"AAAA;;EAEE,sBAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,8BAAA;EACA,kBAAA;EACA,8DAAA;EACA,8DAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;AAEF;;AACA;EACE;IACE,uBAAA;EAEF;EAAA;IACE,yBAAA;EAEF;AACF","sourcesContent":[".lds-ring,\n.lds-ring div {\n  box-sizing: border-box;\n}\n\n.lds-ring {\n  display: inline-block;\n  position: relative;\n  width: 25px;\n  height: 25px;\n  margin-bottom: 15px;\n  margin-right: 25px;\n}\n\n.lds-ring div {\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  width: 25px;\n  height: 25px;\n  margin: 8px;\n  border: 2px solid currentColor;\n  border-radius: 50%;\n  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: currentColor transparent transparent transparent;\n}\n\n.lds-ring div:nth-child(1) {\n  animation-delay: -0.45s;\n}\n\n.lds-ring div:nth-child(2) {\n  animation-delay: -0.3s;\n}\n\n.lds-ring div:nth-child(3) {\n  animation-delay: -0.15s;\n}\n\n.small {\n  width: 10px;\n  height: 10px;\n  margin-bottom: 12px;\n  margin-right: 16px;\n}\n.small div {\n  width: 10px;\n  height: 10px;\n}\n\n@keyframes lds-ring {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
