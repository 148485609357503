import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { catchError, map, of, take, throwError } from "rxjs";
import { ApiService } from "../services/api-services/api.service";
import { AvatarService } from "../services/avatarService/avatar.service";

@Injectable({
    providedIn: 'root'
})

export class PlaceResolverService implements Resolve<any> {
    constructor(private apiService: ApiService, private avatarService: AvatarService)  {

    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const id = route.paramMap.get('id');
        
        return this.apiService.getPlaceById(id).pipe(
            map(place => {
                let placeWithAvatar =this.avatarService.mapAvatarListToPlaceList([place])[0]
                return placeWithAvatar
            }),
            catchError(err => {
                throw new Error(err);
            })
        )
    }
}