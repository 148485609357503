import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() hideSearch: boolean
  @Input() hideAddFavorite: boolean = true;
  @Input() showFloorplanSearch: boolean = false;
  @Output() clickAddFavorite = new EventEmitter<any>();
  @Output() clickAddFavoriteMap = new EventEmitter<any>();
  constructor(private router: Router) {}

  ngOnInit(){
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     if( (event.url.includes('home') || event.urlAfterRedirects.includes('home')) || (event.url.includes('favorite') || event.urlAfterRedirects.includes('favorite') ) ) {
    //       this.hideSearch = false;
    //     }
    //   } else {
    //     this.hideSearch = true
    //   }
    // })
  }
   
  emitClickAddFavorite(){
    // no problem triggering both emits as only one will be triggered at a time 
    this.clickAddFavorite.emit();
    this.clickAddFavoriteMap.emit()
  }
}
