// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --border-color: var(--ion-color-danger);
  --border-width: 0 0 3px 0;
}

.icon-hide {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/header-only/header-only.component.scss"],"names":[],"mappings":"AAEA;EACI,uCAAA;EACA,yBAAA;AADJ;;AAMA;EACI,UAAA;AAHJ","sourcesContent":["\n\nion-toolbar {\n    --border-color: var(--ion-color-danger);\n    --border-width: 0 0 3px 0;\n}\n\n\n\n.icon-hide {\n    opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
