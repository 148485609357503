import { Component, Input, OnInit, Optional } from '@angular/core';
import { ActionSheetController, IonRouterOutlet, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EVENTPAGETYPES } from 'src/app/event/event.page';
import { BackService } from 'src/app/services/back-service/back.service';


@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss'],
})
export class EventHeaderComponent implements OnInit {
  @Input() bookingType: string
  @Input() isEdited: boolean = false;
  constructor(
    private navController: NavController,
    private actionSheetController: ActionSheetController,
    @Optional() private routerOutlet: IonRouterOutlet,
    private translateService: TranslateService,
    private backService: BackService) { }


  ngOnInit() { }


  async goBack() {
    this.backService.goBackToPrevious()
  }

  async goBackToPrevious() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant('navigationInfo'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('continueEditing'),
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
        {
          text: this.translateService.instant('cancelChanges'),
          role: 'destructive',
          data: {
            action: 'continue',
          },
        },
      ]

    });

    await actionSheet.present();
    const condition = await actionSheet.onWillDismiss();


    if (condition.role == 'destructive') {
      this.backService.navigateBack();
    }
  }
}
