import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, map, of, switchMap } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  endCondition: number = 25
  numberOfBookings$ = new BehaviorSubject<number>(0);

  constructor(private alertController: AlertController, private router: Router, private translateService: TranslateService) { 
    this.getBookingCountFromStorage().then(async (result) => {
      if(result) {
        this.numberOfBookings$.next(result);
      }
      
    })
  }


  addBookingCount() {
    let current = this.numberOfBookings$.getValue()
    current += 1;
    this.numberOfBookings$.next(current);
    this.setBookingCountToStorage(this.numberOfBookings$.getValue());
  }

  checkBookingCount() {
    return this.numberOfBookings$.pipe(
      switchMap(count => {
        if (count > this.endCondition) {
          return of();
        }
        if (count % 5 == 0) {
          if (count != 0) {
            return this.router.events.pipe(
              map(event => {
                if (event instanceof NavigationEnd) {
                  if (event.url.includes('boookings') || event.urlAfterRedirects.includes('bookings')) {
                    this.openAlert()
                  }
                }
            }))
          }
        }
        return of();
      })
    )
  }

  private async openAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('feedback.alertText'),
      mode: 'ios',
      cssClass: 'text-size',
      buttons: [
        {
          text: this.translateService.instant('feedback.later'),
          role: 'cancel',

        },
        {
          text: this.translateService.instant('feedback.start'),
          role: 'confirm',

        },
      ],
    });
    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role == 'confirm') {
      this.router.navigate(['/feedback'])
    }

    if (role == 'cancel') {
      this.addBookingCount();
    }
  }

  stopReminder() {
    this.numberOfBookings$.next(this.endCondition + 1);
    this.setBookingCountToStorage(this.numberOfBookings$.getValue());
  }


  async setBookingCountToStorage(value: number) {
    await Preferences.set({
      key: 'bookingsCount',
      value: value.toString()
    }) 
  }

  async getBookingCountFromStorage() {
    let count = await Preferences.get({key: 'bookingsCount'})
    return Number(count.value)
  }

}
