// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-spinner {
  transform: scale(2);
}

ion-grid {
  width: 100%;
}

.spinner-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-item {
  --inner-padding-end: 0;
}

ion-select {
  --padding-end: 0px;
}

::ng-deep .wider-popover {
  --width: 95%;
  --max-width: 250px;
}

ion-textarea {
  --highlight-color-valid: black;
}

.itemSizes::part(native) {
  height: 50px;
}

.subjectLabel {
  margin-top: 7px;
  margin-right: 10px;
}

.repeatMessage {
  z-index: 2;
}

.weekdays-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}`, "",{"version":3,"sources":["webpack://./src/app/event/event.page.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AACA;EACE,WAAA;AAEF;;AACA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,+BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEJ;;AACA;EACE,sBAAA;AAEF;;AAAA;EACE,kBAAA;AAGF;;AADA;EAEE,YAAA;EACA,kBAAA;AAGF;;AADA;EACE,8BAAA;AAIF;;AAIA;EACE,YAAA;AADF;;AAGA;EACE,eAAA;EACA,kBAAA;AAAF;;AAGA;EACE,UAAA;AAAF;;AAGA;EACE,WAAA;EACA,aAAA;EACA,6BAAA;AAAF","sourcesContent":["ion-spinner {\n    transform: scale(2);\n  }\nion-grid {\n  width: 100%;\n}\n\n.spinner-background {\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.15);\n    z-index: 999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\nion-item{\n  --inner-padding-end: 0;\n}\nion-select{\n  --padding-end: 0px;\n}\n::ng-deep .wider-popover\n{\n  --width: 95%;\n  --max-width: 250px;\n}\nion-textarea{\n  --highlight-color-valid: black;\n }\n\nion-item.sc-ion-textarea-ios-h, ion-item .sc-ion-textarea-ios-h {\n  // align-self: center;\n\n}\n\n.itemSizes::part(native) {\n  height: 50px;\n}\n.subjectLabel{\n  margin-top: 7px;\n  margin-right: 10px;\n}\n\n.repeatMessage{\n  z-index: 2;\n  }\n\n.weekdays-container {\n  width: 100%;\n  display: flex;\n  justify-content: space-evenly;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
