// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --min-height: 40px;
  position: relative;
}

ion-title {
  font-size: 16px;
}

.toolbar-title {
  padding-inline: 0px;
}

.refresh-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/page-subtitle/page-subtitle.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AACC;EACG,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;AAEJ","sourcesContent":["ion-toolbar {\n    --min-height: 40px;\n    position: relative;\n}\n\nion-title {\n    font-size: 16px;\n}\n.toolbar-title {\n    padding-inline: 0px;\n }\n\n .refresh-icon {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
