// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --border-color: var(--ion-color-danger) !important;
  --border-width: 0 0 3px 0 !important;
}

.toolbar-title {
  padding-inline: 0px;
  margin-left: 65px;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/add-favorite-maps-modal/add-favorite-maps-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,kDAAA;EACA,oCAAA;AACJ;;AAEA;EACI,mBAAA;EACA,iBAAA;AACJ","sourcesContent":["ion-toolbar {\n    --border-color: var(--ion-color-danger) !important;\n    --border-width: 0 0 3px 0 !important;\n}   \n\n.toolbar-title {\n    padding-inline: 0px;\n    margin-left: 65px;\n }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
