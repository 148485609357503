import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DATETIMEFORMART } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-page-subtitle',
  templateUrl: './page-subtitle.component.html',
  styleUrls: ['./page-subtitle.component.scss'],
})
export class PageSubtitleComponent implements OnInit {
  @Input() title = ''
  @Input() lastUpdated 
  @Input() showRefreshButton = false 
  @Output() doRefresh = new EventEmitter<any>()
  lastUpdatedFormatted = " -"
  constructor(
    public platform: Platform,
    private translateService : TranslateService

  ) { }

  ngOnInit() {
   setInterval(() => {
      const diffInSeconds = moment().diff(this.lastUpdated, 'seconds');
      
      if (diffInSeconds < 60) {
        this.lastUpdatedFormatted = this.translateService.instant("justNow");
      } else if (diffInSeconds < 3600) { // less than 1 hour
        const minutes = Math.floor(diffInSeconds / 60);
        this.lastUpdatedFormatted = `${this.translateService.instant('vor')} ${minutes} minute${minutes !== 1 ? 's' : ''} ${this.translateService.instant('ago')}`;
      } else if (diffInSeconds < 86400) { // less than 1 day
        const hours = Math.floor(diffInSeconds / 3600);
        this.lastUpdatedFormatted = `${this.translateService.instant('vor')} ${hours} hour${hours !== 1 ? 's' : ''} ${this.translateService.instant('ago')}`;
      } else { // 1 day or more
        const days = Math.floor(diffInSeconds / 86400);
        this.lastUpdatedFormatted = `${this.translateService.instant('vor')} ${days} day${days !== 1 ? 's' : ''} ${this.translateService.instant('ago')}`;
      }
    }, 1000);
    
  }

  refreshContent() {
    this.doRefresh.emit()
  }
}
