import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { EVENTPAGETYPES, EventPage } from 'src/app/event/event.page';
import { SearchService } from '../search/search.service';
import { DATETIMEFORMART, SearchRequest } from 'src/app/interfaces/interfaces';
import { LanguageService } from 'src/app/services/languageservice/language.service';
import { IonDatetime, ViewWillEnter } from '@ionic/angular';
import { ApiService } from 'src/app/services/api-services/api.service';
import { map } from 'rxjs';
import { DatetimeFormatService } from 'src/app/services/datetime-format-service/datetime-format.service';

@Component({
  selector: 'app-date-time-select',
  templateUrl: './date-time-select.component.html',
  styleUrls: ['./date-time-select.component.scss'],
})
export class DateTimeSelectComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() searchRequest: SearchRequest;
  @Input() bookingType: string;
  @Input() placeEmailAddress: string;
  @Input() isOnPrem: boolean;
  @Input() isRestofDay: boolean = false;
  @Input() place: any;
  @Output() updateSearchRequestEmitter = new EventEmitter<SearchRequest>();
  @Output() updateIsQuickBooking = new EventEmitter<Boolean>();

  @ViewChild('endTimePicker', { static: false }) endTimePicker: IonDatetime;
  @ViewChild('startTimePicker', { static: false }) startTimePicker: IonDatetime;
  @ViewChild('datePicker', { static: false }) datePicker: IonDatetime;
  @ViewChild('durationPicker', { static: false }) durationPicker: IonDatetime;
  @ViewChild('seriesStartDatePicker', {static:false}) seriesStartDatePicker: IonDatetime;
  @ViewChild('seriesEndDatePicker', {static:false}) seriesEndDatePicker: IonDatetime;

  public durationShow;
  public localStartTimeValue: string;
  public localEndTimeValue: string;
  public minimalDate: string;
  public startDate: string;
  public endDate: string;
  public isToggleEnabled = false;
  public isEndTimeDisabled: boolean = false;
  public isQuickBooking: boolean = false;
  public originalEndTimeValueFromSearchRequest: string;
  public isSameDayMeeting: boolean;
  isAllDaySearch: boolean = false;
  public hoursArrayForTimePicker = [];
  public minutesArrayForTimePicker = [
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
  ];
  public locale;

  constructor(
    public searchService: SearchService,
    public translate: LanguageService,
    private changeDetectorRef: ChangeDetectorRef,
    private apiService: ApiService,
    private dateTimeFormatService: DatetimeFormatService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    this.isAllDaySearch =
      this.searchService.searchRequestState?.searchRequest?.isAllDay == true
        ? true
        : false;
    this.prepareSearchRequest();
    this.localStartTimeValue = this.searchRequest.localStartTimeValue;
    this.calculateDuration(
      this.searchRequest.localStartTimeValue,
      this.searchRequest.localEndTimeValue
    );
    if (this.isSameDayMeeting) {
      if (this.bookingType == EVENTPAGETYPES.editBooking) {
        let EndOfTheDay = moment(this.searchRequest.endTimeUTC)
          .endOf('day')
          .utc();
        let BookingEndDate = moment(this.searchRequest.endTimeUTC).utc();

        if (
          BookingEndDate.hour() == EndOfTheDay.hour() &&
          BookingEndDate.minute() == EndOfTheDay.minute()
        ) {
          this.isRestofDay = true;
          this.isToggleEnabled = true;
          this.originalEndTimeValueFromSearchRequest = moment(
            this.searchRequest.startTimeUTC
          )
            .add(1, 'hour')
            .format(DATETIMEFORMART);
        } else {
          // this.apiService.checkPlaceAvailability(this.placeEmailAddress, moment(this.searchRequest.endTimeUTC).add(1, "minutes").format(DATETIMEFORMART), moment(this.searchRequest.endTimeUTC).endOf("day").utc().format(DATETIMEFORMART),this.isOnPrem).pipe(
          //   map(res => {
          //     this.isRestofDay = !res;
          //   })
          // ).subscribe();
        }
      } else {
        // this.apiService.checkPlaceAvailability(this.placeEmailAddress, moment(this.searchRequest.startTimeUTC).format(DATETIMEFORMART), moment(this.searchRequest.endTimeUTC).endOf("day").utc().format(DATETIMEFORMART),this.isOnPrem).pipe(
        //   map(res => {
        //     this.isRestofDay = !res;
        //   })
        // ).subscribe();
      }
    }
    this.hoursArrayForTimePicker = this.calculateDateTimeHours(
      this.searchRequest.localStartTimeValue
    );

    this.dateTimeFormatService.selectedDateTimeFormat$.subscribe((format) => {
      this.locale = format;
    });
  }

  toggleEndOfTheDayBooking(event) {
    if (event.detail.checked) {
      this.isToggleEnabled = true;
      this.originalEndTimeValueFromSearchRequest =
        this.searchRequest.localEndTimeValue;
      this.isEndTimeDisabled = true;
      this.searchRequest.endTimeUTC = moment(
        this.searchRequest.localEndTimeValue
      )
        .endOf('day')
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = moment(
        this.searchRequest.localEndTimeValue
      )
        .endOf('day')
        .format(DATETIMEFORMART);
      this.localEndTimeValue = this.searchRequest.localEndTimeValue;
      this.changeDetectorRef.detectChanges();
    } else {
      this.isToggleEnabled = false;
      this.localEndTimeValue = this.originalEndTimeValueFromSearchRequest;
      this.searchRequest.endTimeUTC = moment(this.localEndTimeValue)
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = this.localEndTimeValue;
      this.isEndTimeDisabled = false;
      this.updateSearchRequestEmitter.emit(this.searchRequest);
    }
    this.calculateDuration(
      this.searchRequest.localStartTimeValue,
      this.searchRequest.localEndTimeValue
    );
  }

  private checkIfQuickBooking(startTime) {
    let now = moment().add(1, 'minutes').format(DATETIMEFORMART);
    this.isQuickBooking = moment(startTime).isBefore(now);
    this.updateIsQuickBooking.emit(this.isQuickBooking);
  }
  private roundUpToNearestFiveMinutes(date: moment.Moment) {
    const minutes = date.minutes();
    const remainder = minutes % 5;
    if (remainder != 0) {
      date.add(5 - remainder, 'minutes');
    }
    return date;
  }

  private prepareSearchRequest() {  
    if (this.bookingType == EVENTPAGETYPES.quickBooking) {
      this.searchRequest = {
        startTimeUTC: this.roundUpToNearestFiveMinutes(moment()).utc().format(DATETIMEFORMART),
        endTimeUTC: null,
        localStartTimeValue: this.roundUpToNearestFiveMinutes(moment()).format(DATETIMEFORMART),
        localEndTimeValue: null,
        type: null,
      };     
    } else {
      this.startDate = moment(this.searchRequest.localStartTimeValue).format(
        'YYYY-MM-DD'
      );
      this.endDate = moment(this.searchRequest.localEndTimeValue).format(
        'YYYY-MM-DD'
      );
    }
    this.minimalDate = moment().format('YYYY-MM-DD');
    this.setLocalEndTimeValue();
    this.calculateDuration(
      this.searchRequest.localStartTimeValue,
      this.searchRequest.localEndTimeValue
    );
    this.checkIfQuickBooking(this.searchRequest.localStartTimeValue);
  }

  ngAfterViewInit(): void {
    this.searchService.invalidBookingRequest$.next(false);
  }

  private setLocalEndTimeValue() {
    let roundedToNextFiveMinute = this.roundUpMinutes(
      this.searchRequest.localStartTimeValue
    );
    if (this.bookingType == EVENTPAGETYPES.quickBooking) {
      this.localEndTimeValue = moment(this.searchRequest.localStartTimeValue)
        .add(roundedToNextFiveMinute, 'minutes')
        .format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = this.localEndTimeValue;
      this.searchRequest.endTimeUTC = moment(this.localEndTimeValue)
        .utc()
        .format(DATETIMEFORMART);
    } else {
      this.localEndTimeValue = this.searchRequest.localEndTimeValue;
    }

    if (!this.searchService.checkAvailabillityRequest$.getValue()) {
      this.updateSearchRequestEmitter.emit(this.searchRequest);
    }
  }

  private roundUpMinutes(startTime: string) {
    let roundedToNextFiveMinute = 0;
    if (moment(startTime).minute() % 5 == 0) {
      roundedToNextFiveMinute = 5 - (moment(startTime).minute() % 5);
    } else {
      roundedToNextFiveMinute = 10 - (moment(startTime).minute() % 5);
    }
    return roundedToNextFiveMinute;
  }

  private calculateDuration(startTime: string, endTime: string) {
    this.durationShow = null;
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    // const days = duration.asDays().toFixed().padStart(1, '0')
    const hours = (duration.hours() + duration.days() * 24)
      .toString()
      .padStart(2, '0');
    const min = duration.minutes().toString().padStart(2, '0');
    this.durationShow = hours + ':' + min;
    this.isSameDayMeeting = moment(startTime).isSame(moment(endTime), 'day');
  }

  dateChange() {
    const combinedStart = this.mapNewDateAndTime(
      this.datePicker.value,
      this.searchRequest.localStartTimeValue
    );
    const combinedEnd = this.mapNewDateAndTime(
      this.datePicker.value,
      this.searchRequest.localEndTimeValue
    );

    this.searchRequest.startTimeUTC = moment(combinedStart)
      .utc()
      .format(DATETIMEFORMART);
    this.searchRequest.endTimeUTC = moment(combinedEnd)
      .utc()
      .format(DATETIMEFORMART);
    this.searchRequest.localStartTimeValue = combinedStart;
    this.searchRequest.localEndTimeValue = combinedEnd;
    this.localStartTimeValue = combinedStart;
    this.localEndTimeValue = combinedEnd;

    if (!this.searchService.checkAvailabillityRequest$.getValue()) {
      this.updateSearchRequestEmitter.emit(this.searchRequest);
    }
  }

  private mapNewDateAndTime(date: any, time: string) {
    const newDate = moment(date).format('YYYY-MM-DD');
    const newTime = moment(time).format('HH:mm');
    const combined = moment(newDate).add(newTime).format(DATETIMEFORMART);
    return combined;
  }

  startTimeChange() {
    this.searchRequest.startTimeUTC = moment(this.startTimePicker.value)
      .utc()
      .format(DATETIMEFORMART);
    this.searchRequest.localStartTimeValue = moment(
      this.startTimePicker.value
    ).format(DATETIMEFORMART);
    this.localStartTimeValue = moment(this.startTimePicker.value).format(
      DATETIMEFORMART
    );

    if (
      moment(this.localStartTimeValue).valueOf() >
      moment(this.localEndTimeValue).valueOf()
    ) {
      this.searchRequest.endTimeUTC = moment(this.localStartTimeValue)
        .add(30, 'minutes')
        .format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = moment(this.localStartTimeValue)
        .add(30, 'minutes')
        .format(DATETIMEFORMART);
      this.localEndTimeValue = moment(this.localStartTimeValue)
        .add(30, 'minutes')
        .format(DATETIMEFORMART);
    } else {
      this.searchService.isBookable$.next(true);
    }
    
    if (!this.searchService.checkAvailabillityRequest$.getValue()) {   
      this.updateSearchRequestEmitter.emit(this.searchRequest);  
    }
    this.hoursArrayForTimePicker = this.calculateDateTimeHours(
      this.searchRequest.localStartTimeValue
    );
    this.calculateDuration(this.localStartTimeValue, this.localEndTimeValue);
  }

  public endTimeChange() {
    const temp = moment(this.endTimePicker.value).format(DATETIMEFORMART);
    if (
      moment(this.searchRequest.localStartTimeValue).valueOf() >
      moment(temp).subtract(5, 'minutes').valueOf()
    ) {
      this.searchService.invalidBookingRequest$.next(true);
      this.searchService.isBookable$.next(true);
      this.calculateDuration(this.searchRequest.localStartTimeValue, temp);
      this.localEndTimeValue = moment(this.endTimePicker.value).format(
        DATETIMEFORMART
      );
      return;
    } else {
      this.searchService.invalidBookingRequest$.next(false);
      this.searchRequest.endTimeUTC = moment(this.endTimePicker.value)
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = moment(
        this.endTimePicker.value
      ).format(DATETIMEFORMART);
      this.localEndTimeValue = moment(this.endTimePicker.value).format(
        DATETIMEFORMART
      );
    }

    if (!this.searchService.checkAvailabillityRequest$.getValue()) {
      this.updateSearchRequestEmitter.emit(this.searchRequest);
    }
    this.calculateDuration(this.localStartTimeValue, this.localEndTimeValue);
  }

  seriesStartDateChange(){
    const combinedStart = this.mapNewDateAndTime(this.seriesStartDatePicker.value , this.searchRequest.localStartTimeValue);
    this.place.recurrencePattern.startDate = combinedStart;
    this.searchRequest.recurrenceStartDate= combinedStart;
    this.setSeriesEndDateMinimalDate();
    if(!this.searchService.checkAvailabillityRequest$.getValue()) {
      this.updateSearchRequestEmitter.emit(this.searchRequest);  
    }
  }

  seriesEndDateChange(){
    const combinedEnd = this.mapNewDateAndTime(this.seriesEndDatePicker.value , this.searchRequest.localEndTimeValue);
    this.place.recurrencePattern.endDate = combinedEnd; 
    this.searchRequest.recurrenceEndDate= combinedEnd;
    if(!this.searchService.checkAvailabillityRequest$.getValue()) {
      this.updateSearchRequestEmitter.emit(this.searchRequest);  
    }
  }
  public durationChange() {
    //getting the duration variable from html then combining hours with minutes to add to endTime after editing duration
    let duration = this.durationPicker.value.toLocaleString().split(':');
    let hoursInMinutes = +duration[0] * 60;
    let minutes = +duration[1] + hoursInMinutes;
    if (minutes != 0) {
      this.searchService.invalidBookingRequest$.next(false);
      //add minutes to endTime after editing duration
      this.searchRequest.endTimeUTC = moment(this.localStartTimeValue)
        .add(minutes, 'minutes')
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = moment(this.localStartTimeValue)
        .add(minutes, 'minutes')
        .format(DATETIMEFORMART);
      this.localEndTimeValue = moment(this.localStartTimeValue)
        .add(minutes, 'minutes')
        .format(DATETIMEFORMART);
      //check for new timing availibility
      if (!this.searchService.checkAvailabillityRequest$.getValue()) {
        this.updateSearchRequestEmitter.emit(this.searchRequest);
      }
    } else {
      this.localEndTimeValue = this.localStartTimeValue;
      this.searchService.invalidBookingRequest$.next(true);
    }
  }

  public calculateDateTimeHours(startTime: string) {
    this.hoursArrayForTimePicker = [];
    let startTimeToCompare = moment(startTime);
    let endOfDay = moment(startTime).endOf('day');
    let differenceInMinutes = endOfDay.diff(startTimeToCompare, 'minutes');
    let differenceInHours = Math.floor(differenceInMinutes / 60);

    // if minutes in start time is xx:00 we are deducting 1 from hours
    if (differenceInMinutes % 60 == 0) {
      differenceInHours -= 1;
    }
    let arr = [];
    for (let index = 0; index <= differenceInHours; index++) {
      arr.push(index);
    }
    return arr;
  }

  public calculateDateTimeMinutes(startTime: string) {
    let startTimeToCompare = moment(startTime);
    let endOfDay = moment().endOf('day');
    let differenceInMinutes = endOfDay.diff(startTimeToCompare, 'minutes') % 60;

    let arr = [];
    this.minutesArrayForTimePicker = [];
    for (let index = 0; index <= differenceInMinutes; index += 5) {
      arr.push(index);
    }
    return arr;
  }

  public checkIfLastHourWasChosen(startTime: string) {
    //modifying minutes on selecting max hour
    let duration = this.durationPicker.value.toLocaleString().split(':');
    let selectedHour = duration[0];
    if (
      selectedHour ==
      this.hoursArrayForTimePicker[this.hoursArrayForTimePicker.length - 1]
    ) {
      this.minutesArrayForTimePicker = this.calculateDateTimeMinutes(startTime);
    } else {
      this.minutesArrayForTimePicker = [
        0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
      ];
    }
  }
  public setSeriesEndDateMinimalDate(){
    if(!this.place.editMasterEvent) return;
    if ( this.searchRequest.recurrenceType == undefined && this.place.recurrencePattern != undefined) {
      this.searchRequest.recurrenceType = this.place.recurrencePattern.accuranceType;
    }
    if(!this.searchRequest.recurrenceType || this.searchRequest.recurrenceType == 'none') return
    if( this.searchRequest.recurrenceType == "Daily") {
      return moment(this.searchRequest.recurrenceStartDate).add(2,"day").format(DATETIMEFORMART)
   }
    else if (this.searchRequest.recurrenceType == "Workday") {
      let daysToAdd = 6 - moment(this.searchRequest.recurrenceStartDate).day()
      if (daysToAdd == 0) {
        daysToAdd = 7
      }
      return moment(this.searchRequest.recurrenceStartDate).add(daysToAdd, "day").format(DATETIMEFORMART)
    } 

   else if (this.place.recurrencePattern.accuranceType == "Weekly") {
    return moment(this.searchRequest.recurrenceStartDate).add(1,"week").format(DATETIMEFORMART)
   }  
   else if (this.place.recurrencePattern.accuranceType == "AbsoluteMonthly") {
    return moment(this.searchRequest.recurrenceStartDate).add(1,"month").format(DATETIMEFORMART)
   }
  }
}
