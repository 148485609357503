import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-favorites-modal',
  templateUrl: './add-favorites-modal.component.html',
  styleUrls: ['./add-favorites-modal.component.scss'],
})
export class AddFavoritesModalComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  dismissModal() {
    return this.modalController.dismiss('close');
  }
}
