// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-bottom: 5px;
}

.weekdays-container {
  padding-top: 5px;
}

.noData-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workdays-item-container::part(native) {
  display: flex;
  flex-direction: column;
  align-items: start;
}

ion-icon {
  font-size: 32px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/working-hours/working-hours.component.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,QAAA;EACA,kBAAA;AAAF;;AAEA;EACE,gBAAA;AACF;;AACA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;AAGF;;AADA;EACE,eAAA;EACA,kBAAA;AAIF","sourcesContent":[".badge-container {\n  // padding-top:5px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 5px;\n  margin-bottom: 5px;\n}\n.weekdays-container {\n  padding-top: 5px;\n}\n.noData-container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.workdays-item-container::part(native) {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n}\nion-icon {\n  font-size: 32px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
