// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select::part(icon) {
  display: none;
}

ion-icon {
  font-size: 32px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/language-switch/language-switch.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,eAAA;EACA,kBAAA;AACJ","sourcesContent":["ion-select::part(icon) {\n    display: none;\n}\n\nion-icon {\n    font-size: 32px;\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
