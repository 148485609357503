import { ChangeDetectorRef, Component, EventEmitter, Input, input, OnInit, Output } from '@angular/core';
import { FloorplanSearchService } from '../search/floorplan-search.service';
import { ApiService } from 'src/app/services/api-services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionSheetController } from '@ionic/angular';
import { PlacesService } from 'src/app/services/placesService/places.service';
import { finalize } from 'rxjs';
import { EVENTPAGETYPES } from 'src/app/event/event.page';
import { SearchService } from '../search/search.service';
import { NavigationExtras, Router } from '@angular/router';
import { Place } from 'src/app/interfaces/interfaces';
import { StringInfoMessageService } from 'src/app/services/string-info-message/string-info-message.service';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss'],
})
export class PlaceComponent implements OnInit {
  @Input() place ;
  @Input() forFavorite = false;
  @Output() favoriteToggled = new EventEmitter<any>();
  isLoading: boolean;
  isPageLoading
  address:string="";
  constructor(
    private floorplanSearchService: FloorplanSearchService,
    private apiService : ApiService,
    private translateService : TranslateService,
    private actionSheetController : ActionSheetController,
    private placesService : PlacesService,
    public searchService : SearchService,
    private router : Router,
    private stringInfoMessageService:StringInfoMessageService
  ) {}

  ngOnInit() {
    this.address = this.stringInfoMessageService.buildAddressString(this.place)
    this.floorplanSearchService.floorplanInfoList$.subscribe((floorplanInfoList) => {
      if (!floorplanInfoList) return;
      this.place.floorMap = floorplanInfoList.find((floorplan) => {
        if (!floorplan.floorMapImage.startsWith('data')) {
          return (
            JSON.parse(floorplan.floorMapImage).objects.find(
              (object) => object.name == this.place.emailAddress
            ) != undefined
          );
        }
        return false;
      });
    });
  }

  openMap(place) {
    
    this.floorplanSearchService.openMap(place);
  }

  async deleteFromFavorite(place,event?) {
    
    
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant('UnfavoriteQuestion'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('feedback.answers.yes'),
          role: 'destructive'
        },
        {
          text: this.translateService.instant('cancelButton'),
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
    const result = await actionSheet.onDidDismiss();
    if (result.role === 'destructive') {
      this.updateFavorite(place);
    }
}

  public updateFavorite(place: any) {
    this.isLoading = true;
    this.apiService.ToggleFavoritePlace(place.emailAddress).pipe(finalize(() => {
      this.isLoading = false;
      //this.placesService.forceGetAvailblePlacesFromCache(true)
    })).subscribe(() => {
      if(!place.isFav){
        place.favIndexOrdering = this.placesService.favoritePlaces$.value.filter(place=> place.isFav).length;
      }
      place.isFav = !place.isFav;     
      // Emit the event after ensuring `isLoading` is set to false
      this.favoriteToggled.emit(place);
    });
  }

  eventButton(place) {
    this.isPageLoading = true;
    let type = EVENTPAGETYPES.quickBooking;
    place = JSON.stringify(place);
    if (this.searchService.searchState$.getValue().searchRequest != null ) {
      type = EVENTPAGETYPES.scheduledBooking;
    }
    const nav: NavigationExtras = {
      queryParams: { type, place },
      replaceUrl: true,
    };
    this.router.navigate(['event'], nav);
  }

  scheduleEventButton(place) {
    this.isPageLoading = true;
    this.router
      .navigate([
        '/avaliable-time-booking',
        place.emailAddress,
        'scheduleBooking',
      ])
      .finally(() => (this.isPageLoading = false));
  }

  async selectBookingType(place) {
      let actionSheetButtons = [
        {
          text: this.translateService.instant('scheduledBooking'),
          role: 'schedueled',
          cssClass: place.isOnPrem ? 'action-sheet-button-disabled': ''
        },
        {
          text: this.translateService.instant('quickBooking'),
          role: 'quick'
        },
        {
          text: this.translateService.instant('cancelButton'),
          role: 'cancel'
        }
      ]
  
        const actionSheet = await this.actionSheetController.create({
          header: "what type of booking you want to do",
          mode: 'ios',
          buttons: actionSheetButtons
        });
        await actionSheet.present();
        const condition = await actionSheet.onDidDismiss();
        if (condition.role === 'quick') {
          this.eventButton(place)
        }
        if (condition.role === 'schedueled') {
          this.scheduleEventButton(place)
        }
   
  }

}
