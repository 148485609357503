import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header-only',
  templateUrl: './header-only.component.html',
  styleUrls: ['./header-only.component.scss'],
})
export class HeaderOnlyComponent implements OnInit {
  public hideSearch = true;
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(event.url.includes('home') || event.urlAfterRedirects.includes('home')) {
          this.hideSearch = false;
        }
      } else {
        this.hideSearch = true
      }
    })
  }

}
