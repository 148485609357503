// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --inner-padding-end: 0 ;
}

.note {
  margin-top: 3px;
  display: flex;
  align-items: center;
}
.note ion-icon {
  padding-right: 3px;
  font-size: 20px;
}

ion-toggle {
  margin-top: 5px;
  padding-right: 3px;
  padding-bottom: 0;
  padding-top: 10px;
  --track-background-checked: var(--ion-color-danger);
  --handle-max-height: auto;
  --handle-height: 20px;
  --handle-max-width: auto;
  --handle-width: 20px;
  --handle-spacing: -1px;
}

ion-toggle::part(track),
ion-toggle.toggle-checked::part(track) {
  height: 15px;
  width: 47px;
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/date-time-select/date-time-select.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AACA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;AAEF;AAAE;EACA,kBAAA;EACA,eAAA;AAEF;;AACA;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mDAAA;EACA,yBAAA;EACA,qBAAA;EACA,wBAAA;EACA,oBAAA;EACA,sBAAA;AAEF;;AACA;;EAEE,YAAA;EACA,WAAA;EACA,gEAAA;EACA,iBAAA;AAEF","sourcesContent":["ion-item{\n    --inner-padding-end: 0\n  }\n.note {\n  margin-top: 3px;\n  display: flex;\n  align-items: center;\n\n  ion-icon {\n  padding-right: 3px;\n  font-size: 20px;\n  }\n} \nion-toggle {\n  margin-top: 5px;\n  padding-right: 3px;\n  padding-bottom: 0;\n  padding-top: 10px;\n  --track-background-checked: var(--ion-color-danger);\n  --handle-max-height: auto;\n  --handle-height: 20px;\n  --handle-max-width: auto;\n  --handle-width: 20px;\n  --handle-spacing: -1px;\n}\n\nion-toggle::part(track),\nion-toggle.toggle-checked::part(track) {\n  height: 15px;\n  width: 47px;\n  /* Required for iOS handle to overflow the height of the track */\n  overflow: visible;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
