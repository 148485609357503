import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SearchRequest } from 'src/app/interfaces/interfaces';


@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public searchState$ = new BehaviorSubject<SearchState>(new SearchDefaultState())
  public searchRequestState = new SearchRequestState();
  private searchDefaultState = new SearchDefaultState();
  public triggerSearchEffect$ = new BehaviorSubject<boolean>(false)
  public checkAvailabillityRequest$ = new BehaviorSubject<boolean>(false)
  public invalidBookingRequest$ = new BehaviorSubject<boolean>(false);
  public isBookable$ = new BehaviorSubject<boolean>(true)
  public subscription: Subscription;
  constructor() { }


  applySearch(searchRequest: SearchRequest) {
    this.searchRequestState.searchRequest = searchRequest  
    this.searchState$.next(this.searchRequestState)
    this.triggerSearchEffect$.next(true);
  }
  // applySearchSeries(seriesSearchRequest:SearchRequest) {
  //   this.searchRequestState.seriesSearchRequest = seriesSearchRequest    
  //   this.searchRequestState.searchRequest = null;   
  //   this.searchState$.next(this.searchRequestState)
  //   this.triggerSearchEffect$.next(true);
  // }

  resetDefaultSearchState() {
    this.searchState$.next(this.searchDefaultState);
    this.searchRequestState = new SearchRequestState();
    this.triggerSearchEffect$.next(false);
  }

  resetDefaultSearchStateFromBackdrop() {
    this.searchState$.next(this.searchDefaultState);
  }
}


export interface SearchState {
  icon: string,
  color: string,
  fill: string,
  searchRequest?: SearchRequest
}


export class SearchDefaultState implements SearchState {
  icon = 'filter-outline';
  color = 'light';
  fill = 'solid';
  searchRequest = null;

}

export class SearchRequestState implements SearchState {
  icon = 'filter-outline';
  color = 'danger';
  fill = 'solid';
  searchRequest = null;

}