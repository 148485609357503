// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  background-color: transparent;
}

.filter-by-container {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr);
}

ion-checkbox {
  --size: 22px;
  --checkbox-background-checked: var(--ion-color-primary);
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid var(--ion-color-primary);
}

ion-select::part(label) {
  font-weight: 600;
  color: #b2b2b2;
}

ion-select::part(icon) {
  margin-top: 13px;
}

ion-toggle {
  margin-top: 5px;
  padding-right: 3px;
  padding-bottom: 5px;
  padding-top: 10px;
  --track-background-checked: var(--ion-color-danger);
  --handle-max-height: auto;
  --handle-height: 20px;
  --handle-max-width: auto;
  --handle-width: 20px;
  --handle-spacing: -1px;
  overflow: hidden;
}

ion-toggle::part(track),
ion-toggle.toggle-checked::part(track) {
  height: 15px;
  width: 47px;
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/places-settings/places-settings.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAEA;EACI,wBAAA;EACA,qCAAA;AACJ;;AAEA;EACI,YAAA;EACA,uDAAA;AACJ;;AAEE;EACE,kBAAA;EACA,0CAAA;AACJ;;AACE;EACE,gBAAA;EACA,cAAA;AAEJ;;AAAE;EACE,gBAAA;AAGJ;;AAAE;EACE,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mDAAA;EACA,yBAAA;EACA,qBAAA;EACA,wBAAA;EACA,oBAAA;EACA,sBAAA;EACA,gBAAA;AAGJ;;AAAE;;EAEE,YAAA;EACA,WAAA;EACA,gEAAA;EACA,iBAAA;AAGJ","sourcesContent":["ion-item {\n    background-color: transparent;\n}\n\n.filter-by-container {\n    display: grid !important;\n    grid-template-columns: repeat(1,1fr);\n}\n\nion-checkbox {\n    --size: 22px;\n    --checkbox-background-checked: var(--ion-color-primary);\n  }\n  \n  ion-checkbox::part(container) {\n    border-radius: 6px;\n    border: 2px solid  var(--ion-color-primary);\n  }\n  ion-select::part(label) {\n    font-weight: 600;\n    color: #b2b2b2;\n  }\n  ion-select::part(icon) {\n    margin-top: 13px;\n  }\n\n  ion-toggle {\n    margin-top: 5px;\n    padding-right: 3px;\n    padding-bottom: 5px;\n    padding-top: 10px;\n    --track-background-checked: var(--ion-color-danger);\n    --handle-max-height: auto;\n    --handle-height: 20px;\n    --handle-max-width: auto;\n    --handle-width: 20px;\n    --handle-spacing: -1px;\n    overflow: hidden;\n  }\n  \n  ion-toggle::part(track),\n  ion-toggle.toggle-checked::part(track) {\n    height: 15px;\n    width: 47px;\n    /* Required for iOS handle to overflow the height of the track */\n    overflow: visible;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
