import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-conditional-modal',
  templateUrl: './conditional-modal.component.html',
  styleUrls: ['./conditional-modal.component.scss'],
})
export class ConditionalModalComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  confirm(){
    return this.modalController.dismiss(true);
  }
  cancel(){
    return this.modalController.dismiss(false);
  }
}
