import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { FloorPlanInfo } from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api-services/api.service';
import { FloorplanSearchService } from 'src/app/shared/search/floorplan-search.service';

@Component({
  selector: 'app-add-favorite-maps-modal',
  templateUrl: './add-favorite-maps-modal.component.html',
  styleUrls: ['./add-favorite-maps-modal.component.scss'],
})
export class AddFavoriteMapsModalComponent  implements OnInit {
  floorplansList: FloorPlanInfo[] = undefined
  fullFloorplanList: FloorPlanInfo[] = undefined
  floorplanAndUserPreferencesSubscription: any;
  constructor(private modalController: ModalController,private translateService : TranslateService,private floorPlanService: FloorplanSearchService,private apiService: ApiService) {
    // get the floorplans list and the user preferences and filter the floorplans list to not include the ones that are already in the user favorites
    this.floorplanAndUserPreferencesSubscription = combineLatest([
      this.floorPlanService.floorplanInfoList$,
      this.apiService.userPreferences$
    ]).subscribe(([mapsList, userPreferences]) => {
      this.floorplansList = mapsList?.filter(floorplan => {
        return !userPreferences?.userFavoriteMaps.includes(floorplan._id);
      });
      this.fullFloorplanList  = this.floorplansList
    });

   }

  ngOnInit() {}


  searchMapsBySearchBar(event) {
    if(this.floorplansList == undefined) return
    let query = event.target.value.toLowerCase()
    if(query == "") this.floorplansList = this.fullFloorplanList
    this.floorplansList = this.fullFloorplanList.filter(mapDetail => Object.keys(mapDetail).some(key=> {
      if(key != "_id" && key != "organisationId" && key != "lastUpdate" && key != "floorMapImage") {
        let keyValue = mapDetail[key]
        return keyValue?.toString().toLowerCase().includes(query)
      } 
    }))
    
  }

  dismissModal() {
    this.floorplanAndUserPreferencesSubscription.unsubscribe()
    return this.modalController.dismiss('close');
  }
}
