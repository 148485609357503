import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { finalize, Subscription, take } from 'rxjs';
import { EVENTPAGETYPES } from 'src/app/event/event.page';
import { AttendeeInformation, SearchRequest } from 'src/app/interfaces/interfaces';
import { AllPeopleService } from 'src/app/services/all-people-service/all-people.service';
import { ApiService } from 'src/app/services/api-services/api.service';
import { SearchService } from 'src/app/shared/search/search.service';

@Component({
  selector: 'app-attendees-modal',
  templateUrl: './attendees-modal.component.html',
  styleUrls: ['./attendees-modal.component.scss'],
})
export class AttendeesModalComponent implements OnInit, OnDestroy {
  @ViewChild('attendeeInput') attendeeeInput: IonInput;
  getAttendeeSubscripction: Subscription
  filterTerm: string;
  attendeesForFiltering: AttendeeInformation[] = [];
  allAttendeesFromApi: AttendeeInformation[] = [];
  choosenAttendeesToAdd: AttendeeInformation[] = [];
  finalChoosenAttendees: AttendeeInformation[] = [];
  addingAttendde = false;
  acceptedAttendees: AttendeeInformation[] = [];
  declinedAttendees: AttendeeInformation[] = [];
  pendingAttendees: AttendeeInformation[] = [];
  tentativelyAttendees: AttendeeInformation[] = [];
  bookingType:string;
  searchRequest:SearchRequest;
  place:any;

  constructor(private apiService: ApiService, private modalController: ModalController, private searchService: SearchService, private allPeopleService: AllPeopleService) { }


  ngOnInit() { 
    this.splitAttendessByResponse();



    this.allAttendeesFromApi = this.allPeopleService.getPersonList();
    
    this.allAttendeesFromApi = this.allAttendeesFromApi.filter(item => {
      return  !this.finalChoosenAttendees.find( element => {
        return element.userPrincipalName == item.userPrincipalName
      })
    });
    this.attendeesForFiltering = this.allAttendeesFromApi
    
    if(this.bookingType=='editBooking'){
      this.checkAttendeeListAvailability();
    }
  }


  private checkAttendeeListAvailability() {
    this.addingAttendde = true
    let attendeeUPNList: string[] = [];
    if (this.finalChoosenAttendees != null) {
      this.finalChoosenAttendees.forEach(attendee => {
        attendeeUPNList.push(attendee.userPrincipalName);
      })
    }

    if (this.choosenAttendeesToAdd != null) {
      this.choosenAttendeesToAdd.forEach(attendee => {
        attendeeUPNList.push(attendee.userPrincipalName);
      })
    }

    this.checkAttendeAvailability(attendeeUPNList, this.place.startTime, this.place.endTime).subscribe(isAvailable => {
      this.finalChoosenAttendees.forEach(attendee => attendee.isAvailable = isAvailable[attendee.userPrincipalName]);
      this.choosenAttendeesToAdd.forEach(attendee => attendee.isAvailable = isAvailable[attendee.userPrincipalName]);
      this.addingAttendde = false
    });
  }

  checkforChoosenAttendees() {
    this.allAttendeesFromApi.filter(attendee => {
      if(this.choosenAttendeesToAdd.find(a => a.userPrincipalName == attendee.userPrincipalName)) {
        let i = this.allAttendeesFromApi.indexOf(attendee)
        this.allAttendeesFromApi.splice(i, 1)
      }
    })
  }


  filterAttendees(event) {
    if (event.target.value == "") {
      this.attendeesForFiltering = this.allAttendeesFromApi;
      return;
    };
    
    const query = event.target.value.toLowerCase();
    this.attendeesForFiltering = this.allAttendeesFromApi.filter(attendee =>
      attendee.firstName.toLowerCase().indexOf(query) > -1 ||
      attendee.lastName.toLowerCase().indexOf(query) > -1 ||
      attendee.userPrincipalName.toLowerCase().indexOf(query) > -1)
  }

  addAttendee(attendee: AttendeeInformation) {
    this.addingAttendde = true;
    let attendeeUPN:string[]=[];
    attendeeUPN.push(attendee.userPrincipalName);
    
    this.checkAttendeAvailability(attendeeUPN).subscribe(isAvailable => {
      attendee.isAvailable = isAvailable[attendee.userPrincipalName];
      let index = this.allAttendeesFromApi.indexOf(attendee);
      this.allAttendeesFromApi.splice(index, 1)
      this.attendeesForFiltering = this.allAttendeesFromApi
      this.attendeeeInput.value = "";
      attendee.response = "";
      this.finalChoosenAttendees.push(attendee);
      this.splitAttendessByResponse();
    });
  }

  removeAttendee(attendee: AttendeeInformation) {
    let indexfinalChoosenAttendees = this.finalChoosenAttendees.indexOf(attendee);
    this.finalChoosenAttendees.splice(indexfinalChoosenAttendees, 1);
    this.allAttendeesFromApi.push(attendee);
    this.attendeesForFiltering = this.allAttendeesFromApi
    this.splitAttendessByResponse();
  }

  makeOptional(attendee: AttendeeInformation) {
    attendee.isOptional = true
    let index = this.finalChoosenAttendees.indexOf(attendee)
    this.finalChoosenAttendees.splice(index, 1)
    this.finalChoosenAttendees.push(attendee);
    this.splitAttendessByResponse();
  }

  checkAttendeAvailability(userPrincipalNameList: string[], oldStartTime='', oldEndTime='') {

    if(this.bookingType == EVENTPAGETYPES.scheduledBooking){

      return this.apiService.checkAttendeeAvailability(
        userPrincipalNameList,
        this.searchRequest.startTimeUTC,
        this.searchRequest.endTimeUTC,
        ).pipe(finalize(()=> this.addingAttendde = false))
      } else if (this.bookingType == EVENTPAGETYPES.editBooking || this.bookingType == "none") {
                
        return this.apiService.checkAttendeeAvailability(
          userPrincipalNameList,
          this.searchRequest.startTimeUTC,
          this.searchRequest.endTimeUTC,
          oldStartTime,
          oldEndTime).pipe(finalize(()=> this.addingAttendde = false))
      }
  }

  dismissModal(action: boolean) {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.getAttendeeSubscripction?.unsubscribe();
  }

  splitAttendessByResponse() {
    this.acceptedAttendees = []
    this.pendingAttendees = []
    this.choosenAttendeesToAdd = []
    this.tentativelyAttendees = []
    this.declinedAttendees = []
    this.finalChoosenAttendees.forEach(attendee => {
      switch (attendee.response.toLowerCase()) {
          case "accepted":
              this.acceptedAttendees.push(attendee);
              break;
          case "declined":
              this.declinedAttendees.push(attendee);
              break;
          case "none":
          case "notresponded":
              this.pendingAttendees.push(attendee);
              break;
          case "tentativelyaccepted":
            this.tentativelyAttendees.push(attendee);
            break;
          case "":
              this.choosenAttendeesToAdd.push(attendee);
              break;
          default:
              break;
      }
      });
  }
}
