import { Component, NgZone, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { App } from '@capacitor/app';
import {
  ActionSheetController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { Browser } from '@capacitor/browser';

import { ApiService } from './services/api-services/api.service';
import { SettingsPage } from './settings/settings.page';
import { StatusBar, Style } from '@capacitor/status-bar';
import { FeedbackService } from './services/feedback-service/feedback.service';
import { Preferences } from '@capacitor/preferences';
import { filter } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';
import { BackService } from './services/back-service/back.service';
import { LanguageService } from './services/languageservice/language.service';
import { WebSocketService } from './services/web-socket.service';
import { register } from 'swiper/element/bundle';
import * as moment from 'moment';
import { AuthService } from './services/authService/auth.service';

const setStatusBarStyleDark = async () => {
  await StatusBar.setStyle({ style: Style.Dark });
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  SettingsComp = SettingsPage;
  // public appPages = [
  //    { title: 'Home', icon: 'calendar-sharp', route: '/tabs/home', url: null, disabled: false },
  //    { title: 'MyProfile', icon: 'people-circle-outline', route: '/my-profile', disabled: false },
  //    { title: 'Help', icon: 'help-circle', route: '/', url: null, disabled: true },
  //    { title: 'Rate Us', icon: 'star', route: '/', disabled: true },
  //    { title: 'Support', icon: 'chatbubbles-sharp', route: '/support', disabled: true },

  //    { title: 'Settings', icon: 'settings', route: '/settings', disabled: true },
  // ];
  public appPages = [
    //{ title: 'Home', icon: 'calendar-sharp', route: '/tabs/home', url: null, disabled: false },
    {
      title: 'myProfile',
      icon: 'people-circle-outline',
      route: '/my-profile',
      disabled: false,
    },
    {
      title: 'firstAid',
      icon: 'fitness',
      route: '/first-aid',
      disabled: false,
    },
    { title: 'settings', icon: 'settings', route: '/settings', disabled: true },
    {
      title: 'support',
      icon: 'chatbubbles-sharp',
      route: '/support',
      disabled: true,
    },
  ];
  isLoading: boolean;
  isNoUser: boolean;
  applicationInBackgroundTime: moment.Moment = undefined;
  constructor(
    private platform: Platform,
    private zone: NgZone,
    private router: Router,
    private navigation: NavController,
    public apiService: ApiService,
    private feedbackService: FeedbackService,
    private language: LanguageService,
    private backService: BackService,
    private toastController: ToastController,
    private actionSheetController: ActionSheetController,
    private webSocket: WebSocketService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.webSocket.loading$.subscribe((loading) => {
      //we can use markForCheck or detect changes
      this.zone.run(() => this.changeDetectorRef.detectChanges());
    });
    this.initializeApp();
    this.initializeBackButtonHandler();
    register();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // one of main tabs => resetting into initial state
        if (event.url.includes('/tabs')) {
          backService.navigationUrls =
            backService.navigationUrls.length > 1
              ? [
                  backService.navigationUrls[
                    backService.navigationUrls.length - 1
                  ],
                  event.url,
                ]
              : [event.url];
          backService.navigationDepth =
            backService.navigationUrls.length > 1 ? 1 : 0;
          //after login the favorite url not getting stored and 2 elements getting stored ['/login, '/']
        } else if (event.url.includes('login') || event.url == '/') {
          backService.navigationUrls = ['/tabs/favorite'];
          backService.navigationDepth = 0;
        } else {
          if (
            backService.navigationUrls.find((url) => url == event.url) !=
            undefined
          ) {
            backService.navigationUrls.splice(
              backService.navigationUrls.indexOf(event.url),
              1
            );
            backService.navigationUrls.push(event.url);
          } else {
            backService.navigationUrls.push(event.url);
            ++backService.navigationDepth;
          }
        }
      });
  }

  initializeApp() {
    if (localStorage.getItem('selectedlanguage')) {
      this.language.selectLanguage(
        JSON.parse(localStorage.getItem('selectedlanguage'))
      );
    }
    this.platform.ready().then(() => {
      SplashScreen.hide();
      // this.toastController.create({ animated: false }).then(t => { t.present(); t.dismiss(); });
      // this.actionSheetController.create({ buttons:[], animated:false, translucent:false}).then(t => { t.present(); t.dismiss(); });
      if (this.platform.is('android') || this.platform.is('ios')) {
        setStatusBarStyleDark();
        // App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        //   this.zone.run(() => {
        //     this.apiService.LogInLoading.next(true);
        //     this.msalService.instance.handleRedirectPromise().then((response: AuthenticationResult | null) => {
        //       if (response) {
        //         console.log(response);
        //         Preferences.set({
        //           key: 'accessToken',
        //           value: response.accessToken
        //         });
        //         this.router.navigate(['/']);
        //         this.msalService.instance.setActiveAccount(response.account);
        //         Browser.close();
        //         this.router.navigateByUrl('tabs/favorite').then(() => {
        //           this.apiService.LogInLoading.next(false);
        //         });
        //       }
        //       else {
        //         this.apiService.LogInLoading.next(false)
        //       }
        //     });
        //   });
        // });
      }
    });

    this.platform.resume.subscribe((result) => {
      this.authService.fetchValidToken().then((accessToken) => {
        if (accessToken) {
          // Check if current route is a booking page
          const currentUrl = this.router.url;
          if (
            currentUrl.includes('/avaliable-time-booking') ||
            currentUrl.includes('/event')
          ) {
            this.backService.showAreYouSureModal = false;
            // Get previous URL from BackService
            const previousUrl =
              this.backService.navigationUrls[
                this.backService.navigationUrls.length - 2
              ];
            if (previousUrl) {
              // Navigate back to previous page
              this.backService.goBackToPrevious();
              //this.router.navigate([previousUrl], { replaceUrl: true });
            } else {
              // Fallback to favorite page if no previous URL
              this.router.navigate(['/tabs/favorite'], { replaceUrl: true });
            }
          }
        } else if (!this.router.url.includes('/login')) {
          this.router.navigate(['/login'], { replaceUrl: true });
        }
      });
      //do same logic with the MSAL instead
      // this.oidcSecurityService.isAuthenticated$.subscribe( isAuthenticated  => {
      //   if (!isAuthenticated.isAuthenticated) {
      //     this.RefreshSessionIfRefreshtoken();
      //   }
      //   else {
      //     this.isLoading = false;
      //   }
      // });
    });
    this.feedbackService.checkBookingCount().subscribe();
  }

  initializeBackButtonHandler() {
    this.platform.backButton.subscribeWithPriority(10, () => {});
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        App.addListener('backButton', (event) => {
          if (event.canGoBack && !this.router.url.includes('/login'))
            this.handleBackButton();
        });
      }
    });
  }

  handleBackButton() {
    if (!this.router.url.includes('/tabs')) {
      if (!this.router.url.includes('login') && this.router.url != '/') {
        this.backService.goBackToPrevious();
      }
    }
  }

  open(route) {
    this.navigation.navigateForward(route);
  }

  async openInappBrowser(url: string) {
    await Browser.open({ url });
  }
  logout() {
    this.authService.logout();
  }
}
