// managehttp.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpCancleService } from '../services/httpCancelService/http-cancle.service';
import { catchError, map, retry, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../services/authService/auth.service';

const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {
  isAuthenticated: boolean;

  constructor(
    private router: Router,
    private httpCancelService: HttpCancleService,
    private authService: AuthService
  ) {}

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    return from(this.authService.fetchValidToken()).pipe(
      switchMap((token) => {
        const clonedRequest = token
          ? req.clone({
              headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token),
            })
          : req;
        return next.handle(clonedRequest).pipe(
          takeUntil(this.httpCancelService.onCancelPendingRequests()),
          catchError((error) => {
            if (error.status === 0) {
              const params = 'nouser=true';
              this.authService.logout(params);
            }
            if (error.status === 401) {
              this.authService.logout();
            }
            if (error.status === 404) {
              if (error.error.message.error == 'UserNotFound') {
                const params = 'nouser=true';
                this.authService.logout(params);
              } else if (
                error.error.message.error == 'LicenseNotFound' ||
                error.error.message.error == 'LicenseExpired'
              ) {
                const params = 'nolicense=true';
                console.log('LicenseNotFound');
                this.authService.logout(params);
              }
            }
            return throwError(() => error);
          })
        );
      })
    );
  }
}
