import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FloorPlanInfo } from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api-services/api.service';

@Injectable({
  providedIn: 'root'
})
export class FloorplanSearchService {
  public floorplanSearchState$ = new BehaviorSubject<FloorplanSearchState>(new FloorplanSearchDefaultState())
  public floorplanSearchState = new FloorplanSearchRequestState();
  public floorplanSearchDefaultState = new FloorplanSearchDefaultState();
  public triggerFloorplanSearchEffect$ = new BehaviorSubject<boolean>(false)
  public floorplanInfoList$ = new BehaviorSubject<FloorPlanInfo[]>(null)
  public floorplanInfoListLoading$ = new BehaviorSubject<boolean>(false)
  public lastFloorplansInfoUpdate = moment().toString()
  public fetchedSuccessfuly = false
  public desiredFloorplan$ = new BehaviorSubject<FloorPlanInfo>(null)

  constructor(private apiService:ApiService,private router:Router) {  }

  getFloorplanInfoList() {
    if(this.fetchedSuccessfuly == false || moment().diff(this.lastFloorplansInfoUpdate,"minutes") > 240) {
    this.floorplanInfoListLoading$.next(true)
    this.apiService.getFloorPlanInfo().subscribe(res => {
      this.fetchedSuccessfuly = true
      this.floorplanInfoList$.next(res)
      this.lastFloorplansInfoUpdate = moment().toString()
      this.floorplanInfoListLoading$.next(false)
    })
  }
  }

  applySearch(floorplan: FloorPlanInfo) {
    //to reset the floorplan before fetching the next one
    this.floorplanSearchState$.next(this.floorplanSearchDefaultState);
    this.triggerFloorplanSearchEffect$.next(false);
    
    
    this.floorplanSearchState = new FloorplanSearchRequestState();
    this.desiredFloorplan$.next(floorplan)
    this.floorplanSearchState.floorplanSearchRequest = floorplan    
    this.floorplanSearchState$.next(this.floorplanSearchState)
    this.triggerFloorplanSearchEffect$.next(true);
    if(this.router.url.includes("favorite-floor-plans")) this.router.navigate(["/floor-plan"])

  }


  resetDefaultSearchState() {
    this.floorplanSearchState$.next(this.floorplanSearchDefaultState);
    this.floorplanSearchState = new FloorplanSearchDefaultState();
    this.triggerFloorplanSearchEffect$.next(false);
    if(!this.router.url.includes("favorite-floor-plans")) this.router.navigate(["/tabs/favorite-floor-plans"])

  }

  resetDefaultSearchStateFromBackdrop() {
    this.floorplanSearchState$.next(this.floorplanSearchDefaultState);
  }
  
  openMap(place: any){
    //this.desiredFloorMap = this.floorplansInfo?.find(floorplan=> JSON.parse(floorplan.floorMapImage).objects.find(object => object.name == resourseUPN) != undefined);
    let desiredFloorMap = place.floorMap
    desiredFloorMap.triggerringUPN= place.emailAddress ;
    this.applySearch(desiredFloorMap)
    this.router.navigate(["/floor-plan"])
    this.getFloorplanInfoList();
  }

  mapPlacesListToItsFloorMap(placesList: any[]){
    placesList.forEach(place=>{ 
      place.floorMap = this.floorplanInfoList$.value?.find
        (floorplan=>
          {
            if(!floorplan.floorMapImage.startsWith("data")){
              return JSON.parse(floorplan.floorMapImage).objects.find(object => object.name == place.emailAddress) != undefined
            }
            return false
        }
      )
          
  });
    return placesList;
  }
  
}


export interface FloorplanSearchState {
  icon: string,
  color: string,
  fill: string,
  floorplanSearchRequest?: FloorPlanInfo
}


export class FloorplanSearchDefaultState implements FloorplanSearchState {
  icon = 'filter-outline';
  color = 'light';
  fill = 'solid';
  floorplanSearchRequest = null

}

export class FloorplanSearchRequestState implements FloorplanSearchState {
  icon = 'filter-outline';
  color = 'danger';
  fill = 'solid';
  floorplanSearchRequest = null;

}

