import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatetimeFormatService } from 'src/app/services/datetime-format-service/datetime-format.service';

@Component({
  selector: 'app-datetime-format-setting',
  templateUrl: './datetime-format-setting.component.html',
  styleUrls: ['./datetime-format-setting.component.scss'],
})
export class DatetimeFormatSettingComponent implements OnInit {
  locales: { code: string; displayName: string }[] = [
    { code: 'en-US', displayName: 'en-US (US): dd/mm/yy, HH:MM AM' },
    { code: 'en-GB', displayName: 'en-GB (UK): dd/mm/yy, HH:MM' },
    { code: 'de-DE', displayName: 'de-DE (Germany): dd.mm.yy, HH:MM' },
    { code: 'fr-FR', displayName: 'fr-FR (France): dd/mm/yy, HH:MM' },
    { code: 'es-ES', displayName: 'es-ES (Spain): dd/mm/yy, HH:MM' },
    { code: 'zh-CN', displayName: 'zh-CN (China): yy/mm/dd HH:MM' },
    { code: 'ja-JP', displayName: 'ja-JP (Japan): yy/mm/dd HH:MM' },
    { code: 'ko-KR', displayName: 'ko-KR (South Korea): yy. mm. dd. HH:MM' },
    { code: 'ru-RU', displayName: 'ru-RU (Russia): dd.mm.yy, HH:MM' },
    { code: 'pt-BR', displayName: 'pt-BR (Brazil): dd/mm/yy HH:MM' },
  ];
  initValue: string;
  isSelectOpen:boolean = false;

  constructor(
    private translationService: TranslateService,
    public dateTimeFormatService: DatetimeFormatService
  ) {}

  ngOnInit() {
    this.initValue = this.dateTimeFormatService.selectedDateTimeFormat$.value;
  }

  changeDateTimeFormat(event) {
    this.dateTimeFormatService.selectedDateTimeFormat$.next(event.target.value);
    this.dateTimeFormatService.saveToStorage();
    this.onSelectClosed()
  }
  onSelectOpened() {
    this.isSelectOpen=true;
  }

  onSelectClosed() {
    this.isSelectOpen=false;
  }

}
