import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selectedLanguage$ = new BehaviorSubject<any>(null)

  constructor(private translationService: TranslateService) {
    this.translationService.addLangs(['de', 'en-GB'])
  }

   initializeDeviceLanguage() {
    
    
    const browserLaguage = this.translationService.getBrowserLang()    
    if(browserLaguage != ('de' || 'en-GB' )) {
      this.selectedLanguage$.next({text: 'english', value: 'en-GB'})
      return this.translationService.setDefaultLang('en-GB'); 
    } else {
      const list = this.getLanguage()
 
      const l = list.filter(l => l.value == this.translationService.getBrowserLang())
    
      this.selectedLanguage$.next(l[0])
      return this.translationService.setDefaultLang(this.translationService.getBrowserLang());
    }
   }

   selectLanguage(language) {
    this.translationService.use(language.value)
    this.selectedLanguage$.next(language)
   }


   getLanguage() {
    return [
      { text: 'english', value: 'en-GB', textOnBoarding:'EN'},
      { text: 'german', value: 'de', textOnBoarding:'DE'}
    ];
  }
}
