import { Injectable, Injector } from "@angular/core"
import { ComponentStore } from "@ngrx/component-store";

import { catchError, delay, EMPTY, finalize, map, Observable, of, pipe, switchMap, take, tap } from "rxjs";
import { Place, SearchRequest } from "src/app/interfaces/interfaces";

import { ApiService } from "../../services/api-services/api.service";
import { WebSocketService } from "src/app/services/web-socket.service";
import { PlacesService } from "src/app/services/placesService/places.service";


export interface AvailablePlacesState {
    roomsPlacesList: Place[];
    roomsPlacesListError: string;
    roomsPlacesListLoading: boolean;
    type: string;
}




@Injectable()
export class AvailablePlacesStore extends ComponentStore<AvailablePlacesState> {

    readonly filteredList$ = this.select((state) => {
        if (state.type != null) {
            return state.roomsPlacesList.filter(s => s.type == state.type);
        }
        return state.roomsPlacesList;
    });
    readonly roomsPlacesListError$ = this.select((state) => state.roomsPlacesListError);
    readonly roomsPlacesListLoading$ = this.select((state) => state.roomsPlacesListLoading);
    private placesService:PlacesService;
    constructor(private apiService: ApiService,private webSocketService: WebSocketService, private injector: Injector) {

        super({
            roomsPlacesList: [],
            roomsPlacesListError: null,
            roomsPlacesListLoading: null,
            type: null,
        })
        this.webSocketService.loading$.subscribe(loading=>{
            this.setLoading(loading);
        })
    }

    readonly setList = this.updater((state, payload: Place[]) => ({        
        ...state,
        roomsPlacesList: payload,
    }))

    readonly setLoading = this.updater((state, payload: boolean) => ({
        ...state,
        roomsPlacesListLoading: payload
    }))

    readonly setError = this.updater((state, payload: string) => ({
        ...state,
        roomsPlacesListError: payload
    }))


    readonly loadAvailablePlaces = this.effect<void>(
        pipe(
            tap(() => {
                
                this.setList([]);
                this.setError(null);
                this.setLoading(true)
            }),
            switchMap(() => {
                this.placesService = this.injector.get(PlacesService);
                this.setLoading(true)
                return this.placesService.places$.pipe(
                    tap({
                        next: (roomsPlacesList) => {
                            //for trigger the filter after recive new data
                        //     this.setList(roomsPlacesList.sort(item=>{
                        //    return item.isFav == true ? -1 : 1
                        // }))
                        this.setList(roomsPlacesList);
                    },
                        error: (roomsPlacesListError) => {this.patchState({ roomsPlacesListError })
                        this.setLoading(false);
                    }
                    }),
                    catchError(() => EMPTY),
                   
                )
            })
        )
    );

    readonly searchAvailablePlaces = this.effect<SearchRequest>(
        pipe(
            tap(() => {
                this.setList([]);
                this.setError(null);
                this.setLoading(true)             
            }),
            switchMap(searchRequest => {
                this.setLoading(true)
                return this.webSocketService.searchPlaces$.pipe(
                    tap({
                        next: (roomsPlacesList) => {
                        //     this.setList(roomsPlacesList.sort(item=>{
                        //     return item.isFav == true ? -1 : 1
                        //  }));
                        this.setList(roomsPlacesList);
                        },
                        error: (roomsPlacesListError) => {
                            this.setLoading(false);
                            this.patchState({ roomsPlacesListError })
                        }
                    }),
                    catchError(() => EMPTY),
                    finalize(() => {})
                )
            })
        )

    )
    stoploading(){
        this.setLoading(false);
    }
}


