import { Injectable } from '@angular/core';
import { AttendeeInformation } from 'src/app/interfaces/interfaces';
import { ApiService } from '../api-services/api.service';
import * as moment from 'moment';
import { BehaviorSubject, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AllPeopleService {

  private personsList: AttendeeInformation[] = [];
  public personsListLoading$ = new BehaviorSubject<boolean>(false);
  private lastUpdate: string;

  constructor(private apiService:ApiService) { 
    this.lastUpdate = moment().toString();
  }

  public loadPersonList(){
    if ((moment().diff(this.lastUpdate, 'minutes') > 4 || this.personsList.length == 0) && !this.personsListLoading$.value){
      this.personsListLoading$.next(true);
      this.apiService.getAllAttendeesList().pipe(finalize(()=> this.personsListLoading$.next(false))).subscribe(res => {
        this.personsList = res;
        this.personsListLoading$.next(false);
        this.lastUpdate = moment().toString();
      });
    }
  }

  public getPersonList(){
    return this.personsList;
  }
  public setPersonList(value){
    this.personsList=value;
  }

  public extendAttendeeInformation(attendees:AttendeeInformation[]): AttendeeInformation[] {
    attendees.forEach(attendee => {
      let temp = this.personsList.find(person => person.userPrincipalName == attendee.userPrincipalName);
      if(temp?.firstName && temp?.lastName){
        attendee.firstName = temp.firstName;
        attendee.lastName = temp.lastName;
      }else{
        attendee.firstName = attendee.userPrincipalName;
        attendee.lastName = '';
      }
    }); 
    return attendees;
  }

}
