import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { BackService } from 'src/app/services/back-service/back.service';
import { FloorplanSearchService } from '../search/floorplan-search.service';

@Component({
  selector: 'app-go-back-header',
  templateUrl: './go-back-header.component.html',
  styleUrls: ['./go-back-header.component.scss'],
})
export class GoBackHeaderComponent implements OnInit {
  @Input() isContact:boolean = false;
  @Input() hideSearch = true
  @Output() clickSendContactForm = new EventEmitter<any>();
  constructor(private navigation: NavController, private router: Router, private backService: BackService,private floorplanSearchService:FloorplanSearchService) { }

  ngOnInit() {}


  goBack() {
    this.backService.goBackToPrevious();
  }
  emitClickSendContactForm(){    
    this.clickSendContactForm.emit();
  }
}
