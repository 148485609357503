import { Injectable } from '@angular/core';
import { ApiService } from '../api-services/api.service';
import {
  CalendarEvent,
  Place,
  PlaceAvatar,
  PlaceAvatarStorage,
} from 'src/app/interfaces/interfaces';
import { Observable, take, map } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  storageKey: string = 'avatars';
  constructor(private apiService: ApiService) {}

  mapAvatarsToPlaces() {
    if (this.apiService.placesAvatars$.value == null) {
      let loadedAvatars = this.loadAvatars();
      if (loadedAvatars == null || loadedAvatars.length == 0) {
        this.apiService
          .getAvatarsForPlaces()
          .pipe(
            map((avatars) => {
              this.apiService.placesAvatars$.next(avatars);
              this.saveAvatars(avatars);    
            }),
            take(1)
          )
          .subscribe();
      } else {  
        this.apiService.placesAvatars$.next(loadedAvatars);
      }
    }
  }

  mapAvatarListToPlaceList(places: Place[]): Place[] {
    const placesAvatars = this.apiService.placesAvatars$.value;
    if(placesAvatars){
      return places.map((place) => {
        placesAvatars.find(
          (avatar) => avatar.upn == place.emailAddress ? place.avatar = avatar.avatar : null 
        );
        return place;
      });
    }
    else{
      return places;
    }
  }
  mapAvatarListToEventList(events: CalendarEvent[]): CalendarEvent[] {
    const placesAvatars = this.apiService.placesAvatars$.value;
    if(placesAvatars){
      return events.map((event) => {
        placesAvatars.find(
          (avatar) => avatar.upn == event.place.emailAddress ? event.place.avatar = avatar.avatar : null 
        );
        return event;  
      });
    }
    else{
      return events
    }
  }


  private saveAvatars(avatars: PlaceAvatar[]) {
    let dataToSav: PlaceAvatarStorage = {
      avatars: avatars,
      savedAt: moment().toISOString(),
    };
    localStorage.setItem(this.storageKey, JSON.stringify(dataToSav));
  }

  private loadAvatars(): PlaceAvatar[] {
    let loadedData: PlaceAvatarStorage = JSON.parse(
      localStorage.getItem(this.storageKey)
    );
    if (
      loadedData &&
      moment().isBefore(moment(loadedData.savedAt).add(7, 'days'))
    ) {
      return loadedData.avatars;
    }
    return null;
  }
}
