import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UserSettings, showAsOptions } from '../interfaces/interfaces';
import { ApiService } from '../services/api-services/api.service';
import { ToastService } from '../services/toast-service/toast.service';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})

export class SettingsPage implements OnInit {
  showAsOptions = showAsOptions;
  userSettings:UserSettings ={bookingStatus :null}
  isLoading = false
  constructor(private navigation: NavController, private apiService: ApiService, private toastService: ToastService
  ) {
      this.apiService.userPreferences$.subscribe(preferences => {
        this.userSettings.bookingStatus = preferences?.userSettings.bookingStatus
      })
  }

  ngOnInit() {
  }




  open(route) {
    this.navigation.navigateForward(route)
  }

  changeStatus(event) {
    this.isLoading = true
    let userPreferences =  this.apiService.userPreferences$.value
    this.userSettings.bookingStatus = event.target.value
    this.apiService.updateUserSettings(this.userSettings).pipe(catchError(()=> {
      this.isLoading = false
      return this.settingsSomethingWentWrongToast()
    })).subscribe( () => {
      userPreferences.userSettings.bookingStatus = event.target.value;
      this.apiService.userPreferences$.next(userPreferences)
      this.isLoading = false
    })}

  async settingsSomethingWentWrongToast() {
    await this.toastService.openErrorToastAsync('settingsSomethingWentWrongToast');
  }
}
