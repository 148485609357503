// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --border-color: var(--ion-color-danger);
  --border-width: 0 0 3px 0;
}

.send {
  position: absolute;
  right: 0;
  margin-right: 12px;
  color: #E0061C;
  font-weight: bold;
}

ion-icon {
  font-size: 32px;
  margin-right: 12px;
}

.icon-hide {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/go-back-header/go-back-header.component.scss"],"names":[],"mappings":"AAAA;EAEI,uCAAA;EACA,yBAAA;AAAJ;;AAIA;EACI,kBAAA;EACA,QAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;AADJ;;AAIA;EACI,eAAA;EACA,kBAAA;AADJ;;AAIA;EACI,UAAA;AADJ","sourcesContent":["ion-toolbar {\n\n    --border-color: var(--ion-color-danger);\n    --border-width: 0 0 3px 0;\n}\n\n\n.send{\n    position: absolute;\n    right: 0;\n    margin-right: 12px; \n    color: #E0061C;\n    font-weight: bold;\n}\n\nion-icon {\n    font-size: 32px;\n    margin-right: 12px;\n}\n\n.icon-hide {\n    opacity: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
